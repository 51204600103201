import React from "react";
import italeeApi from "../../api/italeeApi";
import { Link } from "react-router-dom";
import { Form } from "semantic-ui-react";
import MessageBox from "./../../modals/MessageBox";
import history from "../../history";

class Register extends React.Component {
  state = {
    open: false,
    acctname: "",
    phonenum: "",
    faxnum: "",
    email: "",
    zipcode: "",
    acctnum: "",
    password: "",
    yourname: "",
    alreadRegisterd: "hidden",
    wrongZipcode: "hidden",
    chkField: {
      acctname: "",
      phonenum: "",
      faxnum: "",
      email: "",
      zipcode: "",
      acctnum: "",
      password: "",
      yourname: ""
    }
  };

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  success = () => {
    history.push("/");
  };

  checkField = async (name, value) => {
    this.setState({
      chkField: {
        ...this.state.chkField,
        [name]: value
      }
    });
  };

  checkValidation = async (acctnum, zipcode) => {
    const fld = this.state;

    this.setState({
      chkField: {
        ...this.state.chkField,
        acctname: fld.acctname === "" ? "error" : "",
        phonenum: fld.phonenum === "" ? "error" : "",
        faxnum: fld.faxnum === "" ? "error" : "",
        email: fld.email === "" ? "error" : "",
        zipcode: fld.zipcode === "" ? "error" : "",
        acctnum: fld.acctnum === "" ? "error" : "",
        password: fld.password === "" ? "error" : "",
        yourname: fld.yourname === "" ? "error" : ""
      }
    });

    if (this.state.acctnum === "" || this.state.zipcode === "") return null;

    const result = await italeeApi.get(`/register/${acctnum}/${zipcode}`);
    const { userIdExist, notMatchZip } = result.data;
    const already = userIdExist ? "" : "hidden";
    const wrong = notMatchZip ? "" : "hidden";

    await this.setState({
      alreadRegisterd: already,
      wrongZipcode: wrong
    });

    await this.submitForm(userIdExist, notMatchZip);
  };

  submitForm = async (already, wrong) => {
    if (!already && !wrong) {
      const postData = {
        Acctnum: this.state.acctnum,
        Acctname: this.state.acctname,
        Phonenum: this.state.phonenum,
        Faxnum: this.state.faxnum,
        Email: this.state.email,
        Password: this.state.password,
        Accttitle: this.state.yourname
      };

      const result = await italeeApi.post(`/register/`, postData);
      if (result.status === 200) this.handleOpen();
      else this.handleClose();
    }
  };

  render() {
    const fld = this.state.chkField;
    const MessageOptions = {
      style: "ok",
      title: "Account Registration",
      message: "Registred normally, Now You can login.",
      size: "tiny",
      open: this.state.open,
      handleClose: this.handleClose,
      success: this.success,
      reject: null
    };
    return (
      <div>
        <div
          className="ui fixed inverted menu"
          style={{ backgroundColor: "#2d3847" }}
        >
          <div className="ui container">
            <div>
              <img src="/mainLogo.png" alt="logo" />
            </div>
          </div>
        </div>
        <div className="ui main container">
          <h3 className="header">Agreement and Disclosure Statement</h3>
          <div className="ui padded segment">
            <div className="ui warning message">
              <div className="header">
                You must have STORE or OFFICE and have accont with Lab Italee to
                open e-ORDER account.
              </div>
            </div>
            <h5 className="header">OVERVIEW</h5>
            This agreement (Agreement is made and entered between the Customer
            (Customer,you,or yours who applies for e-Order Services. The
            Customer agrees to be bound by the Agreement, if the Customer has
            applied for, and accepted or used the Lab e-Order Services. The
            Customer is an existing customer of the Lab, and Customer represents
            that it has the necessary equipment and telephone line to receive
            the contemplated facsimile or e-mail transmissions from the Lab.
            <h5 className="header">e-ORDER SERVICES</h5>
            The Customer authorizes the Lab to transmit facsimile or send
            e-mail, to a facsimile machine or personal computer of the Customer
            designation, a transcript statement (“Transcript Statement?
            pertaining to the previous business day work-in-process per Customer
            order request, between the hours of 6:00 a.m. and 10:00 a.m.,
            Pacific Standard Time, and on a daily basis (Monday through
            Saturday, except Holidays) until the service is terminated. In
            addition, all e-Order Services are subject to the provisions on the
            Customer account Credit Application, and any other agreement, which
            is applicable to the Customer account.
            <h5 className="header">TERMS & CONDITIONS</h5>
            This Agreement shall continue and remain in force and effect until
            it is terminated. The Lab or the Customer shall have the right to
            terminate this Agreement for cause or for no cause. If termination
            is by Customer it shall be only upon written notice to Lab. Lab may
            terminate this Agreement without notice, at any time. The e-Order
            Services shall begin on the second business day following the full
            execution and delivery of this Agreement.
            <h5 className="header">BUSINESS DAYS</h5>
            For purposes of this Agreement, our business days (“Business Day(s)?
            are Monday through Saturday, excluding Sunday and Holidays.
            <h5 className="header">ACCOUNT</h5>
            An “account?is one where the account holder is engaged to sales of
            eyewear including italee products.
            <h5 className="header">INFORMATION PROVIDED</h5>
            The specific categories of information to be provided in the
            Transcript Statement are: account number, account name, job order
            number, date of transaction, and description of transaction. Each
            Transcript Statement will reflect information as of the previous
            business day of transmission. You will be deemed to have received
            each Transcript Statement as of our transmission.
            <h5 className="header">DISCLOSURE OF INFORMATION</h5>
            In the ordinary course of business, the Lab may disclose information
            to third parties about the Customer account(s) or the transactions
            the Customer makes.
            <div className="ui ordered list">
              <div className="item">
                Where it is necessary to complete transactions to or from the
                Customer account(s) or to resolve errors involving them; or
              </div>
              <div className="item">
                In order to verify the existence and condition of the Customer
                account(s) for a third party, such as a credit bureau or a
                merchant; or
              </div>
              <div className="item">
                If the Customer gives the lab written permission.
              </div>
            </div>
            <h5 className="header">FEES FOR e-ORDER SERVICES</h5>
            The e-Order Services shall be provided at a fee free. Other fees, as
            described in the Credit Application, which govern the Customer
            relationship may apply.
            <h5 className="header">DUTY TO REVIEW</h5>
            You agree to review each Transcript Statement upon receipt. You
            agree to notify us immediately if you think there is an error or an
            unauthorized transaction shown on your Transcript Statement. If we
            transmit a Transcript Statement, which contains or discloses an
            unauthorized order or an alteration, you must notify us promptly of
            that fact, but no later than 3 days after we transmit the Transcript
            Statement to you. If you do not, we may refuse to reimburse you for
            subsequent unauthorized transactions by the same wrongdoer(s). If
            you claim a credit or refund because of a forgery, alteration or any
            other unauthorized order, you agree to provide us with an affidavit
            containing whatever reasonable information we require concerning
            your account, the transaction and the circumstances surrounding your
            claim. You also agree to make a report to the police and provide us
            with a copy of the report upon our request. You assume full
            responsibility for monitoring and reviewing the activity of your
            account and the work of your employees and agents.
            <h5 className="header">CANCELLATION OF ORDERS</h5>
            We will not be responsible for failure to cancel the order if we
            have already shipped the item, or if we do not have a reasonable
            opportunity to respond to and process your cancellation of order.
            <h5 className="header">LIMITATION ON LIABILITY</h5>
            Notwithstanding the foregoing, you understand that by providing the
            Information for your review, we are not making any representations
            or warranties regarding whether the items reflected will or will not
            ultimately charge against your account. Furthermore, we well not be
            liable in the event the Information fails to list every order
            received for payment against your account.
            <h5 className="header">LIABILITY FOR FAILURE TO TRANSMIT</h5>
            Except as specifically provided in this Agreement or where the law
            requires a different standard, the Customer agrees that the Lab
            shall not be responsible for any loss, property damage, or bodily
            injury, whether caused by the equipment, transmission, or the Lab in
            providing the e-Order Services. The Lab shall not be responsible for
            any direct, indirect, special, or consequential, economic or other
            damages arising in any way out of the installation, use or
            maintenance of the equipment or transmission or provision of the
            e-Order Services. If the Lab does not complete an electronic
            transmission on time according to the Agreement, the Lab shall not
            be liable for the Customer losses or damages, for instance:
            <div className="ui ordered list">
              <div className="item">
                If circumstances beyond the Lab control (such as fire, flood,
                labor dispute, or power or computer failure) to prevent the
                electronic transmission from being completed, despite reasonable
                precautions that the Lab has taken.
              </div>
              <div className="item">
                If the Customer account(s) has been placed a hold due
                delinquency, closed or if the Customer's e-Order Services
                Agreement has been cancelled.
              </div>
              <div className="item">
                If the Lab does not receive the information necessary to
                complete the e-Order Services.
              </div>
            </div>
            <h5 className="header">RIGHT OF REFUSAL</h5>
            The Lab reserves the right to refuse to honor any order request for
            any reason, including but not limited to, refusal because the order
            request looks suspicious or counterfeit, or appears to have been
            altered.
            <h5 className="header">INDEMNIFICATION</h5>
            In consideration for our providing the Service to you, you agree to
            defend, indemnify and hold us, and our officers, directors, agents
            and employees, harmless against any and all liability, actions,
            losses, costs, damages or expenses, including attorney fees and
            expenses, which we may sustain or incur by reason of, or in
            consequence of providing the Services in accordance with this
            Agreement.
            <h5 className="header">FUTURE CHANGES</h5>
            The Lab reserves the right to change this Agreement from time to
            time. The Lab may delete, add to, modify, or otherwise change the
            e-Order Services in whole or in part. Notice will be given to the
            Customer promptly before the effective date of such deletions,
            additions, or modifications that will raise the costs to the
            Customer of providing the e-Order Services. No advance notice is
            required, however, if such deletions, additions, modifications, or
            otherwise change is made for security reasons. The Customer agrees
            to be bound by such deletions, additions, and modifications until
            termination of the Agreement.
            <h5 className="header">OTHER AGREEMENTS, RULES & REGULATIONS</h5>
            In addition to this Agreement, the Customer agrees to be bound by
            and will comply with the requirements of the applicable Credit
            Application, the Lab policy, and applicable state and federal laws
            and regulations.
            <h5 className="header">JOINT LIABILITY</h5>
            If the Customer account is a joint account, each separately, and
            both of the Customers together are responsible for complying with
            the Agreement (called “joint and several liability").
            <p />
            <div className="ui divider" />
            <Form className="ui form" autoComplete="off">
              <div className="field">
                <h4 className="ui header">Acount Information</h4>

                <div
                  className={`ui orange message  ${this.state.alreadRegisterd}`}
                >
                  <div className="header">Account is already registered.</div>
                </div>
                <div
                  className={`ui negative message ${this.state.wrongZipcode}`}
                >
                  <div className="header">
                    Please enter correct billing address zipcode.
                  </div>
                </div>

                <div className="sixteen fields">
                  <div className={`four wide field ${fld.acctname}`}>
                    <label>Account Name</label>
                    <input
                      type="text"
                      name="acctname"
                      placeholder="account name"
                      value={this.state.acctname}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.phonenum}`}>
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="phonenum"
                      placeholder="phone number"
                      value={this.state.phonenum}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.faxnum}`}>
                    <label>Fax Number</label>
                    <input
                      type="text"
                      name="faxnum"
                      placeholder="fax number"
                      value={this.state.faxnum}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.email}`}>
                    <label>eMail address</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="email address"
                      value={this.state.email}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.zipcode}`}>
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="postal code"
                      value={this.state.zipcode}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <h4 className="ui header">LogIn Information</h4>
                <div className="sixteen fields">
                  <div className={`four wide field ${fld.acctnum}`}>
                    <label>Existing Lab Italee Account Number</label>
                    <input
                      type="text"
                      name="acctnum"
                      placeholder="account number"
                      value={this.state.acctnum}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.password}`}>
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      placeholder="password"
                      value={this.state.password}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                  <div className={`three wide field ${fld.yourname}`}>
                    <label>Your Name</label>
                    <input
                      type="text"
                      name="yourname"
                      placeholder="yourname"
                      value={this.state.yourname}
                      onChange={e => {
                        this.checkField(
                          e.target.name,
                          e.target.value !== "" ? "" : "error"
                        );
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="ui primary button large"
                onClick={() => {
                  const { acctnum, zipcode } = this.state;
                  this.checkValidation(acctnum, zipcode);
                }}
              >
                Register
              </div>
              <Link className="ui danger button large" to="/login">
                Cancel
              </Link>
            </Form>
          </div>
          <p />
        </div>
        <MessageBox
          options={MessageOptions}
          open={true}
          //onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export default Register;
