import italeeApi from "../api/italeeApi";

export const getEstimation = async Jinfo => {
  const {
    lensStyle,
    lensMaterial,
    lensCoating,
    lensPolish,
    lensSoftPolish,
    lensTint,
    lensGroove,
    lensUV
  } = Jinfo;

  let edgeType = "";
  if (lensPolish) edgeType += "POLISH,";
  if (lensSoftPolish) edgeType += "SPOLISH,";
  if (lensGroove) edgeType += "GROOVE,";
  if (lensUV) edgeType += "UV400,";
  edgeType += "NOTHING";

  //console.log(edgeType);

  const postObject = {
    LensCode: lensStyle,
    LensMat: lensMaterial,
    Coat: lensCoating,
    Edge: edgeType,
    Tint: lensTint,
    Number: "RX1",
    Job: "C"
  };
  const response = await italeeApi.put("/amounts/getEstimation", postObject);
  //console.log(response);
  return response.data;
};

// export const amountLens = () => {
//   return 100;
// };

// export const amountCoating = () => {
//   return 50;
// };

// export const amountTint = () => {
//   return 10;
// };

// export const amountEdge = () => {
//   return 5;
// };
