import React from "react";
import NumberFormat from "react-number-format";

class InvLensometer extends React.Component {
  state = { infos: {} };

  componentDidUpdate() {
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });
  }

  formatNumber = (val, scale) => {
    return (
      <NumberFormat
        value={val}
        displayType={"text"}
        decimalScale={scale}
        fixedDecimalScale={true}
      />
    );
  };

  render() {
    //const val = this.state.infos;
    const val = this.props.datas;
    return (
      <>
        <div className="ui basic segment">
          <h4>Lensometer Reading</h4>
          <table className="ui compact definition table">
            <thead>
              <tr>
                <th></th>
                <th>SPH</th>
                <th>CYL</th>
                <th>AXIS</th>
                <th>Add</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>OD</td>
                <td>{this.formatNumber(val.r_odSph, 2)}</td>
                <td>{this.formatNumber(val.r_odCyl, 2)}</td>
                <td>{val.r_odAxis}</td>
                <td>{this.formatNumber(val.r_odAdd, 2)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>OS</td>
                <td>{this.formatNumber(val.r_osSph, 2)}</td>
                <td>{this.formatNumber(val.r_osCyl, 2)}</td>
                <td>{val.r_osAxis}</td>
                <td>{this.formatNumber(val.r_osAdd, 2)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default InvLensometer;
