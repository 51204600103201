import React from "react";
import _ from "lodash";

class InvFrame extends React.Component {
  state = { infos: {} };

  componentDidUpdate() {
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });
  }

  render() {
    //const val = this.state.infos;
    const val = this.props.datas;
    return (
      <div>
        <table className="ui celled table">
          <tbody>
            <tr>
              <td className="two wide active">Order Type</td>
              <td className="four wide">{val.frameJobType}</td>
              <td className="two wide active">Lens Order</td>
              <td className="four wide">{_.toUpper(val.lensJobType)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvFrame;
