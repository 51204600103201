import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import LensDropDown from "../subcomponent/LensDropDown";
import Rxinfo from "../subcomponent/Rxinfo";
import PatientName from "../subcomponent/PatientName";
import ShippingAddress from "../subcomponent/ShppingAddress";
import LensDimention from "./../subcomponent/LensDimention";
import CustomInfo from "../subcomponent/CustomInfo";
import OtherServices from "../subcomponent/OtherServices";
import EdgeType from "../subcomponent/EdgeType";
import italeeApi from "../../api/italeeApi";

import Header from "./../Header";
import Footer from "./../../Footer";
import { Button } from "semantic-ui-react";
import { resetOrder, setRedux, setReduxOrder } from "./../../actions/index";
import SpecialMemo from "../subcomponent/SpecialMemo";
import ViewPreOrder from "../../components/subitems/ViewPreOrder";

//import SubmitModal from "./../../modals/SubmitModal";
import ViewOrder from "./ViewOrder";
import { getEstimation } from "./../Functions";


class PlaceOrder extends React.Component {
  state = {
    customerInfo: false,
    coatings: [{ key: 0, text: "No AR Coating", value: "none" }],
    modalOpen: false,
    open: false,
    ponum: null,
    visible: true,
    validate: [],
    reset: 0
  };

  componentDidMount() {
    this.props.setRedux("jobType", "UNCUT");
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  showModal = async () => {
    await this.submitValidation();
    this.setState({ modalOpen: true });
  };

  nullVa = val => {
    return _.isNull(val) ? "" : val;
  };

  submitValidation = async () => {
    const job = this.props;
    const ship = this.props;
    var result = [];

    this.nullVa(job.patientName) === "" && result.push("patientName");
    this.nullVa(job.patientLastName) === "" && result.push("patientLastName");
    this.nullVa(ship.shipNum) === "" && result.push("shipNum");
    this.nullVa(ship.shipName) === "" && result.push("shipName");
    this.nullVa(ship.shipAddress) === "" && result.push("shipAddress");
    this.nullVa(ship.shipCity) === "" && result.push("shipCity");
    this.nullVa(ship.shipZip) === "" && result.push("shipZip");
    this.nullVa(job.lensStyle) === "" && result.push("lensStyle");
    this.nullVa(job.lensMaterial) === "" && result.push("lensMaterial");
    this.nullVa(job.lensColor) === "" && result.push("lensColor");
    this.nullVa(job.lensCoating) === "" && result.push("lensCoating");
    this.nullVa(job.lensEdgeType) === "" && result.push("lensEdgeType");

    //basic rx info validate
    if (job.lensSide === "pair" || job.lensSide === "Right Only") {
      this.nullVa(job.od_sph) === "" && result.push("od_sph");
      this.nullVa(job.od_cyl) === "" && result.push("od_cyl");
      this.nullVa(job.od_pd) === "" && result.push("od_pd");

      //axis only require when cyl is bigger then 0
      if (this.nullVa(job.od_cyl) !== "" && _.toNumber(job.od_cyl) !== 0)
        this.nullVa(job.od_axis) === "" && result.push("od_axis");

      //when the lens is non sv, then check Add and Seght
      if (job.lensType === "pr" || job.lensType === "mf") {
        this.nullVa(job.od_add) === "" && result.push("od_add");
        this.nullVa(job.od_seg) === "" && result.push("od_seg");
      } else {
        if (job.lensDigital === 1) {
          this.nullVa(job.od_oc) === "" && result.push("od_oc");
        }
      }
    }

    if (job.lensSide === "pair" || job.lensSide === "Left Only") {
      this.nullVa(job.os_sph) === "" && result.push("os_sph");
      this.nullVa(job.os_cyl) === "" && result.push("os_cyl");
      this.nullVa(job.os_pd) === "" && result.push("os_pd");

      //axis only require when cyl is bigger then 0
      if (this.nullVa(job.os_cyl) !== "" && _.toNumber(job.os_cyl) !== 0)
        this.nullVa(job.os_axis) === "" && result.push("os_axis");

      //when the lens is non sv, then check Add and Seght
      if (job.lensType === "pr" || job.lensType === "mf") {
        this.nullVa(job.os_add) === "" && result.push("os_add");
        this.nullVa(job.os_seg) === "" && result.push("os_seg");
      } else {
        if (job.lensDigital === 1) {
          this.nullVa(job.os_oc) === "" && result.push("os_oc");
        }
      }

      //
    }

    //uncut measurement validate
    if (job.jobType === "UNCUT") {
      this.nullVa(job.aValue) === "" && result.push("aValue");
      this.nullVa(job.bValue) === "" && result.push("bValue");
      this.nullVa(job.edValue) === "" && result.push("edValue");
      this.nullVa(job.dblValue) === "" && result.push("dblValue");
    }

    //opposite cyl sign check
    ((job.od_cyl > 0 && job.os_cyl < 0) || (job.od_cyl < 0 && job.os_cyl > 0))
      && result.push("oppCylSign");


    this.setState({ validate: result });
  };

  onSubmit = () => {
    const headers = {
      "Content-Type": "application/json"
    };

    const job = this.props;
    const ship = this.props;

    let _polish = job.lensPolish ? "POLISH" : "";
    let _spolish = job.lensSoftPolish ? "SOFT POLISH" : "";
    let lensEdgeTreatment = _polish + _spolish
    
    const postObject = {
      acctAddress: job.acctAddress,
      acctCif: job.acctCif,
      acctCity: job.acctCity,
      acctName: job.acctNum,
      acctNum: job.acctNum,
      acctState: job.acctState,
      acctZip: job.acctZip,
      authStatus: job.authStatus,
      jobType: "UNCUT",
      aValue: job.aValue,
      bValue: job.bValue,
      dblValue: job.dblValue,
      edValue: job.edValue,
      lensSide: job.lensSide,
      lensCoating: job.lensCoating,
      lensColor: job.lensColor,
      lensKind: job.lensKind,
      lensCutting: job.lensCutting,
      lensEdgeTreat: lensEdgeTreatment,
      lensEdgeType: job.lensEdgeType,
      lensEyesize: job.lensEyesize,
      lensGroove: job.lensGroove,
      lensMaterial: job.lensMaterial,
      lensMaterialText: job.lensMaterialText,
      lensOption: job.lensOption,
      lensShape: job.lensShape,
      lensStyle: job.lensStyle,
      lensStyleText: job.lensStyleText,
      lensTint: job.lensTint,
      lensTintDesc: job.lensTintDesc,
      lensUV: job.lensUV,
      loginAcct: job.loginAcct,
      od_add: job.od_add,
      od_axis: job.od_axis,
      od_base: job.od_base,
      od_cyl: job.od_cyl,
      od_npd: job.od_npd,
      od_oc: job.od_oc,
      od_pd: job.od_pd,
      od_prismh: job.od_prismh,
      od_prismh_d: job.od_prismh_d,
      od_prismv: job.od_prismv,
      od_prismv_d: job.od_prismv_d,
      od_seg: job.od_seg,
      od_sph: job.od_sph,
      od_thk: job.od_thk,
      os_add: job.os_add,
      os_axis: job.os_axis,
      os_base: job.os_base,
      os_cyl: job.os_cyl,
      os_npd: job.os_npd,
      os_oc: job.os_oc,
      os_pd: job.os_pd,
      os_prismh: job.os_prismh,
      os_prismh_d: job.os_prismh_d,
      os_prismv: job.os_prismv,
      os_prismv_d: job.os_prismv_d,
      os_seg: job.os_seg,
      os_sph: job.os_sph,
      os_thk: job.os_thk,
      patientName: job.patientLastName + " " + job.patientName,
      pofInformation: job.pofInformation,
      panto: job.panto,
      vertexOD: job.vertexOD,
      vertexOS: job.vertexOS,
      wrap: job.wrap,
      shipAddress: ship.shipAddress,
      shipCity: ship.shipCity,
      shipName: ship.shipName,
      shipNum: ship.shipNum,
      shipState: ship.shipState,
      shipZip: ship.shipZip,
      jobInstruction: ship.jobInstruction,
      specialMemo: ship.specialMemo,
      cfComment: job.jobInstruction
    };

    italeeApi
      .post("/lens", postObject, { headers })
      .then(response => {
        console.log(response.data);
        this.setState({ ponum: "W" + response.data });
        this.setState({ modalOpen: false, open: true });
        //clear field
        this.props.setRedux("resetCode", this.props.resetCode + 1);
        this.props.resetOrder();
        //
        return "Success";
      })
      .catch(err => {
        console.log(err);
        this.setState({ modalOpen: false });
        return "Fail, Error while POSTING data to Server.";
      });

    //close confirm modal and open confirmation page modal
  };

  customerInfoUpdate = val => {
    this.setState({ customerInfo: val });
  };

  closeViewOrder = () => {
    this.setState({ open: false });
  };

  coatingUpdate = () => {
    const { lensStyle, lensMaterial, lensTint, lensCoatingAval } = this.props;
    const aval = lensTint === "NOTINT" || lensTint === "" ? 1 : 2;
    const keys = lensCoatingAval;

    //console.log(`/lens/coat?l=${lensStyle}&m=${lensMaterial}&aval=${aval}&keys=${keys}`);

    if (lensStyle && lensMaterial) {
      italeeApi
        .get(`/lens/coat?l=${lensStyle}&m=${lensMaterial}&aval=${aval}&keys=${keys}`)
        .then(response => {
          this.setState({
            coatings: [
              { key: 0, text: "No AR Coating", value: "none" },
              ...response.data
            ]
          });
          const temp = _.find(response.data, item => {
            return item.value === this.props.lensCoating;
          });
          if (!temp) {
            this.props.setRedux("lensCoating", "");
          }
        })
        .catch(() => {
          console.log("Error loading coatings...");
        });
    }
  };

  amountUpdate = async () => {
    const Jinfo = this.props;
    //update lens price
    const responseAmount = await getEstimation(Jinfo);
    const lensAmount = responseAmount.find(e => e.itemName === "Lens");
    const coatingAmount = responseAmount.find(e => e.itemName === "Coating");
    const tintingAmount = responseAmount.find(e => e.itemName === "Tinting");
    const edgeAmount = responseAmount.find(e => e.itemName === "Edging");
    const totalAmount = responseAmount.find(e => e.itemName === "Total");

    await this.props.setReduxOrder(
      "lensAmount",
      lensAmount ? lensAmount.itemAmount : 0
    );
    await this.props.setReduxOrder(
      "coatingAmount",
      coatingAmount ? coatingAmount.itemAmount : 0
    );
    await this.props.setReduxOrder(
      "tintingAmount",
      tintingAmount ? tintingAmount.itemAmount : 0
    );
    await this.props.setReduxOrder(
      "edgeAmount",
      edgeAmount ? edgeAmount.itemAmount : 0
    );
    await this.props.setReduxOrder(
      "totalAmount",
      totalAmount ? totalAmount.itemAmount : 0
    );
  };

  returnModal = () => {
    const success = {
      onSubmit: this.onSubmit,
      onClose: this.closeModal,
      message:
        "Once the order is submitted, you will no longer able to make changes to your order.",
      status: true
    };

    const successOppCylSign = {
      onSubmit: this.onSubmit,
      onClose: this.closeModal,
      message:
        "Once the order is submitted, you will no longer able to make changes to your order.\nWarning: Cyl are opposite sign.",
      status: true
    };

    const failed = {
      onSubmit: this.onSubmit,
      onClose: this.closeModal,
      message: "Please review and correct the field(s) hightlighted in red.",
      status: false
    };

    
    if (_.isEmpty(this.state.validate)) {
      return <ViewPreOrder open={this.state.modalOpen} result={success} />;
    } else if (this.state.validate[0] === "oppCylSign") {
      return <ViewPreOrder open={this.state.modalOpen} result={successOppCylSign} />;
    }
    else {
      return (
        <ViewPreOrder
          open={this.state.modalOpen}
          result={failed}
          closeInvoice={this.state.closeModal}
        />
      );
    }
    
  };

  returnRx = () => {
    return (
      <Rxinfo validate={this.state.validate} lensType={this.props.lensType} />
    );
  };

  render() {
    const { customerInfoUpdate, coatingUpdate } = this;

    return (
      <div>
        <Header currMenu='JOBS' />
        <div className='ui main container'>
          <div className='ui container'>
            <h3 className='ui header'>
              <i className='share square icon outline' />
              <div className='content'>Uncut Job</div>
            </h3>

            <div className='ui segment'>
              <div className='ui two column very relaxed grid'>
                <div className='column'>
                  <PatientName
                    PatientField={true}
                    validate={this.state.validate}
                  />
                </div>
                <div className='column'>
                  <ShippingAddress validate={this.state.validate} />
                </div>
              </div>
              <div className='ui vertical divider'>~</div>
            </div>

            <div className='ui segment'>
              <LensDropDown
                customerInfoUpdate={customerInfoUpdate}
                coatingUpdate={coatingUpdate}
                validate={this.state.validate}
                amountUpdate={this.amountUpdate}
              />
            </div>
            <div className='ui segment'>
              <OtherServices
                coatings={this.state.coatings}
                EdgeTreat={false}
                EdgeOrLaser={"none"}
                validate={this.state.validate}
                amountUpdate={this.amountUpdate}
              />
            </div>

            <div className='ui segment'>
              <div className='ui form'>
                <div className='three fields'>
                  <div className='field'>
                    <EdgeType />
                  </div>
                  <div className='field'>
                    <LensDimention validate={this.state.validate} />
                  </div>
                  <div className='field'>
                    <CustomInfo customerInfo={this.state.customerInfo} />
                  </div>
                </div>
              </div>
            </div>

            <div className='ui segment'>{this.returnRx()}</div>

            <div className='ui segment'>
              <SpecialMemo />
            </div>

            <div className='ui eight column centered grid'>
              <div className='column'>
                <Button
                  size='big'
                  onClick={() => {
                    this.props.setRedux("resetCode", this.props.resetCode + 1);
                    this.props.resetOrder();
                  }}
                >
                  CLEAR
                </Button>
              </div>
              <div className='column'>
                <Button positive size='big' onClick={this.showModal}>
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* <SubmitModal
          open={this.state.modalOpen}
          //closeModal={this.closeModal}
          //onSubmit={this.onSubmit}
          result={this.returnValue}
        /> */}

        {this.returnModal()}

        <ViewOrder
          open={this.state.open}
          closeInvoice={this.closeViewOrder}
          ponum={this.state.ponum}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo, ...state.ship.shipInfo };
};

export default connect(mapStateToProps, {
  setRedux,
  resetOrder,
  setReduxOrder
})(PlaceOrder);
