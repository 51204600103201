import React from "react";
import { Modal, Button } from "semantic-ui-react";
import italeeApi from "../api/italeeApi"

class ShapeModal extends React.Component {
    _isMounted = false;
    state = { shapeDataOD: "", shapeDataOS: "", shapeName: "", eyeSize: "", options: { shapeOpen: false } }

    componentDidMount = async () => {
        await this.setState({ options: this.props.options })
        this._isMounted = true;
    }

    componentDidUpdate = async () => {

        if (this.props.options !== this.state.options) {
            this.setState({ ...this.state, options: this.props.options })

            const { shapeName, eyeSize, shapeOpen } = this.props.options;
            if (shapeOpen) {
                const response = await italeeApi.get(`lens/GetShapeData/${shapeName}/${eyeSize}`);
                await this.setState({
                    shapeDataOD: response.data[0].shapedataod,
                    shapeDataOS: response.data[0].shapedataos,
                    shapeName: response.data[0].shapename,
                    eyeSize : response.data[0].eyeSize
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    closeModal = () => { this.setState({ shapeOpen: false }) }

    render() {

        const style = {
            border: "0px solid",
            height: "280",
            width: "600",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

        const style2 = {
            border: "0px solid",
            height: "280",
            width: "600"

        }

        const style3 = {
            fontSize: "20px"
        }

        return (
            <Modal
                size='small'
                open={this.state.options.shapeOpen}
                onClose={this.state.options.fnShapeClose}
                closeOnEscape={true}
                closeOnDimmerClick={true}
            >

                <Modal.Header>Shape View</Modal.Header>
                <Modal.Content>

                    <div style={style} >
                        <svg style={style2}>
                            <polyline points={this.state.shapeDataOD} stroke="black" fill="transparent" strokeWidth="2" strokeLinecap="round" />
                            <polyline points={this.state.shapeDataOS} stroke="black" fill="transparent" strokeWidth="2" strokeLinecap="round" />
                            <text x="10" y="270" fill="black" style={style3}>{this.state.shapeName}</text>
                        </svg>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="CLOSE"
                        onClick={this.state.options.fnShapeClose}
                    />
                </Modal.Actions>

            </Modal>
        )
    }

}



export default ShapeModal;
