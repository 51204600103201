import React from "react";
import { Dimmer, Loader, Modal } from "semantic-ui-react";

class Modalbox extends React.Component {
  state = { modalOpen: false };

  componentDidUpdate() {
    this.state.modalOpen !== this.props.modalOpen &&
      this.setState({ modalOpen: this.props.modalOpen });
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
      <Modal
        //trigger={<Button onClick={this.handleOpen}>Show Modal</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        basic
        size="fullscreen"
        dimmer="inverted"
      >
        <Modal.Content>
          <Dimmer active inverted>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        </Modal.Content>
      </Modal>
    );
  }
}
export default Modalbox;
