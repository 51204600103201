import React from "react";
import Header from "./../Header";
import Footer from "./../../Footer";
import DataTable from "../subcomponent/DataTable";
import PatientName from "../subcomponent/PatientName";
import italeeApi from "../../api/italeeApi";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Pagination } from "semantic-ui-react";

class Statements extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    const tDate1 = new Date();
    const tDate2 = new Date();
    tDate1.setMonth(tDate1.getMonth() - 4);

    const sDate = tDate1.getMonth() + 1 + "/1/" + tDate1.getFullYear();
    const eDate =
      tDate2.getMonth() +
      1 +
      "/" +
      tDate2.getDate() +
      "/" +
      tDate2.getFullYear();

    this.state = {
      sDate,
      eDate,
      tableBody: [],
      currPage: 1,
      pageSize: 25,
      totalPage: 1,
      totalRecNum: 1
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    //get Statement history
    italeeApi
      .get(`/statement/GetAcctInfos?Acctnum=${this.props.acctNum}`)
      .then(response => {
        const {
          totalBalance,
          agedCurr,
          aged30,
          aged60,
          aged90,
          aged120,
          currentMonth
        } = response.data[0];
        this._isMounted && 
        this.setState({
          totalBalance,
          agedCurr,
          aged30,
          aged60,
          aged90,
          aged120,
          currentMonth
        });
      })

      .catch(err => {
        console.log(err);
      });
    //get payment history
    italeeApi
      .get(
        `/statement/GetPaymentInfos?sdate=${this.state.sDate}&edate=${
          this.state.eDate
        }&AcctNum=${this.props.acctNum}`
      )
      .then(response => {
        if(this._isMounted){
        this.setState({ paymentCurr: response.data[4].amount });
        this.setState({ payment30: response.data[3].amount });
        this.setState({ payment60: response.data[2].amount });
        this.setState({ payment90: response.data[1].amount });
        this.setState({ payment120: response.data[0].amount });
        }
      })
      .catch(err => {
        console.log(err);
      });
    //get invoice list
    this._isMounted && await this.loadStatementPage();

    //load total record count
    this._isMounted && await this.getTotalStatementRecNum();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  StatementRefresh = () =>{
        //get invoice list
        this.loadStatementPage();
        //load total record count
        this.getTotalStatementRecNum();
        //Load payment history 
        this.getPaymentHistory();
  }

  getPaymentHistory = () => {
    italeeApi
    .get(
      `/statement/GetPaymentInfos?sdate=${this.state.sDate}&edate=${
        this.state.eDate
      }&AcctNum=${this.props.acctNum}`
    )
    .then(response => {    
      this.setState({ paymentCurr: response.data[4].amount });
      this.setState({ payment30: response.data[3].amount });
      this.setState({ payment60: response.data[2].amount });
      this.setState({ payment90: response.data[1].amount });
      this.setState({ payment120: response.data[0].amount });      
    })
    .catch(err => {
      console.log(err);
    });

    //get Statement history
    italeeApi
    .get(`/statement/GetAcctInfos?Acctnum=${this.props.acctNum}`)
    .then(response => {
      const {
        totalBalance,
        agedCurr,
        aged30,
        aged60,
        aged90,
        aged120,
        currentMonth
      } = response.data[0];
      this.setState({
        totalBalance,
        agedCurr,
        aged30,
        aged60,
        aged90,
        aged120,
        currentMonth
      });
    })
    .catch(err => {
      console.log(err);
    });    
  }

  loadStatementPage = (page = 1, size = 25) => {
    italeeApi
      .get(
        `/statement/GetStatementDetail?page=${page}&size=${size}&acct=${
          this.props.acctNum
        }`
      )
      .then(response => {
        this.setState({ tableBody: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getTotalStatementRecNum = () => {
    italeeApi
      .get(`/statement/GetRecNum?acct=${this.props.acctNum}`)
      .then(response => {
        const totalRec = response.data[0].totalRec === 0 ? 1 : response.data[0].totalRec;
        this.setState({ totalRecNum: totalRec });
        this.setState({
          totalPage: Math.ceil(this.state.totalRecNum / this.state.pageSize)
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  monthToText = month => {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "Feburary";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return month;
    }
  };


  render() {
    const {
      currentMonth,
      totalBalance,
      agedCurr,
      aged30,
      aged60,
      aged90,
      aged120,
      paymentCurr,
      payment30,
      payment60,
      payment90,
      payment120
    } = this.state;

    const headerValues = {
      currentMonth,
      agedCurr,
      aged30,
      aged60,
      aged90,
      aged120,
      totalBalance,
      paymentCurr,
      payment30,
      payment60,
      payment90,
      payment120
    };

    const tableTitles = [
      {
        key: 1,
        title: [
          { key: 1, data: "Date" },
          { key: 2, data: "Invoice #" },
          { key: 3, data: "Job Name" },
          { key: 4, data: "PO #" },
          { key: 5, data: "Amount" },
          { key: 6, data: "Paid" }
        ]
      }
    ];

    // const tableBody = [
    //   {
    //     key: 1,
    //     body: [
    //       { key: 1, data: "Date" },
    //       { key: 2, data: "Date" },
    //       { key: 3, data: "Date" },
    //       { key: 4, data: "Date" },
    //       { key: 5, data: "Date" },
    //       { key: 6, data: "Date" }
    //     ]
    //   }
    // ];

    const tableFoot = [
      {
        key: 1,
        foot: [
          { key: 1, data: "" },
          { key: 2, data: "" },
          { key: 3, data: "" },
          { key: 4, data: "" },
          { key: 5, data: "" },
          { key: 6, data: "" }
        ]
      }
    ];

    return (
      <div>
        <Header currMenu="STATEMENTS" />

        <div className="ui main container">
          <div className="ui container">
            <h3 className="ui header">
              <i className="file alternate icon outline" />
              <div className="content">Recent Statement</div>
            </h3>

            <div className="ui segment">
              <div className="ui form">
                <div className="two fields">
                  <div className="eight wide field">
                    <PatientName StatementRefresh={this.StatementRefresh} />
                  </div>
                  <div className="eight wide field" />
                </div>
              </div>

              <table className="ui celled  structured compact black table">
                <thead>
                  <tr>
                    <th rowSpan="2" />
                    <th rowSpan="2">
                      Month of {this.monthToText(headerValues.currentMonth)}
                    </th>
                    <th colSpan="4">Past Due Amount</th>
                  </tr>
                  <tr>
                    <th>Over 30</th>
                    <th>Over 60</th>
                    <th>Over 90</th>
                    <th>Over 120</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Aged Amounts</td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.agedCurr}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.aged30}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.aged60}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.aged90}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.aged120}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Payments</td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.paymentCurr}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.payment30}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.payment60}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.payment90}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={headerValues.payment120}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <DataTable
                tableTitle={tableTitles}
                tableBody={this.state.tableBody}
                tableFoot={tableFoot}
                tableColor={"red"}
              />
              <Pagination
                defaultActivePage={this.state.currPage}
                totalPages={this.state.totalPage}
                onPageChange={async (e, data) => {
                  await this.loadStatementPage(
                    data.activePage,
                    this.state.pageSize
                  );
                }}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(mapStateToProps)(Statements);
