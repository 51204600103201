import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../actions";
import history from "../history";
import UserIdManage from "./../modals/UserIdManage";
import _ from "lodash";

class Header extends React.Component {
  state = {
    modalOpen: false,
    order: {
      lensAmount: 0,
      coatingAmount: 0,
      tintingAmount: 0,
      edgeAmount: 0
    }
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  showModal = () => {
    this.setState({ modalOpen: true });
  };

  componentDidMount() {
    if (this.props.jobInfo.authStatus !== "accepted") {
      history.push("/");
    }
  }

  componentDidUpdate() {
    const order = this.props.jobInfo.Order;
    this.state.order !== order && this.setState({ ...this.state, order });
  }

  renderLoginList = () => {
    const { loginAcct, acctNum } = this.props.jobInfo;
    return loginAcct === acctNum ?
      <div className="item" onClick={this.showModal}>
        Manage LogIn Id
      </div>
      :
      <div></div>

  }

  renderLogin = () => {
    if (this.props.jobInfo.authStatus === "accepted") {
      return (
        <div className="ui simple dropdown item">
          <div className="text">
            <img className="ui avatar image" src="avatar.jpg" alt="avatar" />
            {this.props.jobInfo.loginAcct}
          </div>
          <i className="dropdown icon" />
          <div className="menu">
            <div className="header">
              <i className="chess king icon" />
              {this.props.jobInfo.acctNum}
            </div>
            {this.renderLoginList()}
            <div className="item" onClick={this.props.logOut}>
              Log Out
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Link to="#" className="item">
          LogIn
        </Link>
      );
    }
  };

  rednerOrderList = () => {
    const { order } = this.state;
    const total =
      order.lensAmount +
      order.tintingAmount +
      order.coatingAmount +
      order.edgeAmount;
    let url = window.location.href.split("/");
    _.reverse(url);

    return url[0] === "placeorder" || url[0] === "poforder" ? (
      <div className="ui simple dropdown item">
        <div className="text">Total : ${total}</div>
        <i className="dropdown icon" />
        <div className="menu">
          <div className="item">Lens : ${order.lensAmount}</div>
          <div className="item">Coating : ${order.coatingAmount}</div>
          <div className="item">Tinting : ${order.tintingAmount}</div>
          <div className="item">EdgeTreatment : ${order.edgeAmount}</div>
        </div>
      </div>
    ) : (
        <div></div>
      );
  };

  renderCurrentMenu = itemMenu => {
    if (this.props.currMenu === itemMenu) return "blue active";
    else return "";
  };

  render() {
    return (
      <div
        className="ui fixed inverted menu"
        style={{ backgroundColor: "#2d3847" }}
      >
        <div className="ui container">
          <div>
            <Link to="/home">
              <img src="/mainLogo.png" alt="logo" />
            </Link>
          </div>
          <div className="ui simple dropdown item blue">
            Place New Job{" "}
            <i className={`dropdown icon ${this.renderCurrentMenu("JOBS")}`} />
            <div className="menu">
              <Link to="/placeorder" className={`item`}>
                Uncut Job
              </Link>
              <Link to="/poforder" className={`item`}>
                POF Job
              </Link>
            </div>
          </div>
          <Link
            to="/statements"
            className={`item ${this.renderCurrentMenu("STATEMENTS")}`}
          >
            Statement
          </Link>
          <Link
            to="/invoices"
            className={`item ${this.renderCurrentMenu("INVOICES")}`}
          >
            Invoice Search
          </Link>
          <Link
            to="/shippingstatus"
            className={`item ${this.renderCurrentMenu("SHIPPEDJOBS")}`}
          >
            Shipped Jobs
          </Link>
          <Link
            to="/jobstatus"
            className={`item ${this.renderCurrentMenu("JOBSTATUS")}`}
          >
            Job Status
          </Link>
          <div className="right menu">
            {this.rednerOrderList()}
            {this.renderLogin()}
          </div>
        </div>
        <UserIdManage
          closeModal={this.closeModal}
          showModal={this.showModal}
          open={this.state.modalOpen}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return { ...state.job, ownProps };
};

export default connect(mapStateToProps, { logOut })(Header);
