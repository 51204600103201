import React from "react";

class InvHeader extends React.Component {
  state = { infos: {} };

  componentDidUpdate() {
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });
  }
  render() {
    //const val = this.state.infos;
    const val = this.props.datas;
    const invDate = new Date(val.ordDate);
    //console.log(val);
    return (
      <>
        <div className="ui basic segment">
          <div className="ui three column very relaxed stackable grid">

            <div className="column">
              <div className="field">
                <div className="ui column grid">
                  <img src="/italeelogo_print.png" alt="logo" width="180" />
                </div>
              </div>
            </div>

            {val.invnum ?
              (<div className="column">
                <div className="field">
                  <div className="five wide column">
                    <div className="ui small label">INVOICE NUMBER</div>

                  </div>
                  <div className="eleven wide column">
                    <h3>{val.invnum}</h3>
                  </div>
                </div>
              </div>
              ) :
              (
                <div className="column">
                  <div className="field">
                    <div className="ui two column grid">
                      <div className="five wide column">
                        <div className="ui small label">USERID</div>
                      </div>
                      <div className="eleven wide column">
                        <h3>{val.orderUserId}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="column">
              <div className="field">
                <div className="five wide column">
                  <div className="ui small label">DATE</div>
                </div>
                <div className="eleven wide column">
                  <h3>{invDate.toLocaleDateString()}</h3>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="ui segment">
          <div className="ui two column very relaxed stackable grid">
            <div className="column">
              <div className="field">
                <label className="ui small blue label">BILL TO</label> {val.billAcctnum}
              </div>
              <div className="field small">{val.billAcctName}</div>
              <div className="field small">{val.billAddress}</div>
              <div className="field small">{val.billAddrCity}, {val.billAddrState} {val.billAddrZip}</div>
            </div>
            <div className="column">
              <div className="field">
                <label className="ui small green label">SHIP TO</label> {val.shipnum}
              </div>
              <div className="field small">{val.shipAcctName}</div>
              <div className="field small">{val.shipAddress}</div>
              <div className="field small">{val.shipAddrCity}, {val.shipAddrState} {val.shipAddrZip}</div>
            </div>
          </div>
        </div>

        <div className="ui basic segment">
          <div className="ui two column very relaxed stackable grid">
            <div className="column">
              <div className="ui small label">PO NUMBER</div> {val.ponum}
            </div>
            <div className="column">
              <div className="ui small label">PATIENT NAME</div> {val.patientName}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InvHeader;
