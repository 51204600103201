import React from "react";
import Header from "./../Header";
import Footer from "./../../Footer";
import DataTable from "./../subcomponent/DataTable";
import PatientName from "../subcomponent/PatientName";
import SearchBox from "./../subcomponent/SearchBox";
import italeeApi from "../../api/italeeApi";
import { connect } from "react-redux";
import { Pagination } from "semantic-ui-react";
import ViewInvoice from "../subitems/ViewInvoice";

class Invoices extends React.Component {
  _isMounted = false;
  state = {
    tableBody: [],
    requestInfo: { currPage: 1 },
    totalPage: 1,
    pageSize: 25,
    open: false,
    invnum: null
  };

  async componentDidMount() {
    this._isMounted = true;
    var date = new Date(); //get now
    let currDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ); // remove time, get only date
    let apiDateFr = Date.parse(currDate);
    let apiDateTo = Date.parse(currDate);
    let currPage = 1;
    let acctNum = this.props.acctNum;
    let patName = "*";
    let invNum = "*";
    let invType = "A";

    if (this._isMounted) {
      await this.setState({
        requestInfo: {
          apiDateFr,
          apiDateTo,
          currPage,
          acctNum,
          patName,
          invNum,
          invType
        }
      });
    }
    this.loadAll();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  propFunc = async (apiDateFr, apiDateTo, patName, invNum, invType) => {
    await this.setState(prevState => ({
      requestInfo: {
        ...prevState.requestInfo,
        apiDateFr,
        apiDateTo,
        patName,
        invNum,
        invType
      }
    }));
    await this.loadAll();
  };

  loadAll = async () => {
    await this.loadList();
    await this.loadTotalRecNo();
  };

  loadList = async () => {
    const {
      apiDateFr,
      apiDateTo,
      currPage,
      patName,
      invNum,
      invType
    } = this.state.requestInfo;
    const response = await italeeApi.get(
      `Invoices/GetList/${this.props.acctNum}/${apiDateFr}/${apiDateTo}/${patName}/${invNum}/${invType}/${currPage}`
    );
    await this.setState({ tableBody: response.data });
  };

  loadTotalRecNo = async () => {
    const {
      apiDateFr,
      apiDateTo,
      patName,
      invNum,
      invType
    } = this.state.requestInfo;

    const response = await italeeApi.get(
      `Invoices/GetTotalRecNum/${this.props.acctNum}/${apiDateFr}/${apiDateTo}/${patName}/${invNum}/${invType}`
    );
    const totalRec = response.data[0].totalCount;
    const totalPage = Math.ceil(totalRec / this.state.pageSize);
    this.setState({ totalPage });
  };

  showInvoice = val => {
    this.setState({ open: true, invnum: val.value });
  };

  closeInvoice = () => {
    this.setState({ open: false });
  };

  render() {
    const tableTitles = [
      {
        key: 1,
        title: [
          { key: 1, data: "Date" },
          { key: 2, data: "Invoice #" },
          { key: 3, data: "Job Type" },
          { key: 4, data: "Job Name" },
          { key: 5, data: "PO #" },
          { key: 6, data: "Amount" },
          { key: 7, data: "Paid" },
          { key: 8, data: "Tracking #" }
        ]
      }
    ];

    // const tableBody = [
    //   {
    //     key: 1,
    //     body: [
    //       { key: 1, data: "Date1" },
    //       { key: 2, data: "Date2" },
    //       { key: 3, data: "Date3" },
    //       { key: 4, data: "Date4" },
    //       { key: 5, data: "Date5" },
    //       { key: 6, data: "Date6" }
    //     ]
    //   }
    // ];

    const tableFoot = [
      {
        key: 1,
        foot: [
          { key: 1, data: "" },
          { key: 2, data: "" },
          { key: 3, data: "" },
          { key: 4, data: "" },
          { key: 5, data: "" },
          { key: 6, data: "" },
          { key: 7, data: "" },
          { key: 8, data: "" }          
        ]
      }
    ];

    return (
      <div>
        <Header currMenu="INVOICES" />
        <div className="ui main container">
          <div className="ui container">
            <h3 className="ui header">
              <i className="table icon" />
              <div className="content">Invoices</div>
            </h3>

            <div className="ui segment">
              <div className="ui two column very relaxed grid">
                <div className="column">
                  <PatientName StatementRefresh={this.loadAll} />
                </div>
                <div className="column">
                  <SearchBox loadAll={this.propFunc} />
                </div>
              </div>
              <div className="ui vertical divider">~</div>
            </div>

            <div className="ui header small">Invoice List</div>
            <DataTable
              tableTitle={tableTitles}
              tableBody={this.state.tableBody}
              tableFoot={tableFoot}
              tableColor={"red"}
              showInvoice={this.showInvoice}
              Sortable
            />
            <Pagination
              defaultActivePage={this.state.requestInfo.currPage}
              totalPages={this.state.totalPage}
              onPageChange={async (e, data) => {
                await this.setState(prevState => ({
                  requestInfo: {
                    ...prevState.requestInfo,
                    currPage: data.activePage
                  }
                }));
                await this.loadAll();
              }}
            />
          </div>
        </div>

        <Footer />
        <ViewInvoice
          open={this.state.open}
          closeInvoice={this.closeInvoice}
          invnum={this.state.invnum}
          title={"Invoice Details"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(
  mapStateToProps,
  null
)(Invoices);
