import React from "react";
import italeeApi from "../../api/italeeApi";
import { connect } from "react-redux";
import { Modal, ModalHeader } from "semantic-ui-react";
import InvHeader from "./../subcomponent/InvHeader";
import InvRxInfo from "./../subcomponent/InvRxInfo";
import InvLensometer from "./../subcomponent/InvLensometer";
import InvJobInfo from "./../subcomponent/InvJobInfo";
import InvDetail from "./../subcomponent/InvDetail";
import InvFrame from "./../subcomponent/InvFrame";

class ViewInvoice extends React.Component {
  state = { open: false, invnum: this.props.invnum, infos: {}, details: [] };

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
      this.getInvoiceDetail();
    }
  }

  getInvoiceDetail = async () => {
    await this.setState({ invnum: this.props.invnum });
    const response = await italeeApi.get(
      `/invoices/InvoiceDetail/${this.state.invnum}`
    );
    await this.setState({ infos: response.data.invoiceGeneralInfos });
    await this.setState({ details: response.data.invoiceChageLists });
  };

  showInvJobinfo = () => {
    const toComp = this.state.infos.jobType;
    if (toComp === "UNCUT")
      return <InvJobInfo datas={this.state.infos} uncut={true}></InvJobInfo>;
    else return <div></div>;
  };

  showRxInfo = () => {
    const toComp = this.state.infos.jobType;
    if (toComp === "UNCUT" || toComp === "FULL")
      return (
        <div>
          <InvFrame datas={this.state.infos} />
          <InvRxInfo datas={this.state.infos}></InvRxInfo>
          <InvLensometer datas={this.state.infos}></InvLensometer>
        </div>
      );
    else return <div></div>;
  };

  //handleOpen = () => this.setState({ open: true });
  //handleClose = () => this.setState({ open: false });
  render() {
    const inlineStyle = {
      modal: {
        marginTop: "0px !important",
        marginLeft: "0px",
        marginRight: "auto"
      }
    };
    return (
      <Modal
        open={this.state.open}
        onClose={this.props.closeInvoice}
        centered={false}
        style={inlineStyle.modal}
        size={"large"}
      >
        <ModalHeader>
          <h3>{this.props.title}</h3>
        </ModalHeader>
        <Modal.Content>
          <div>
            <InvHeader datas={this.state.infos}></InvHeader>
            {this.showRxInfo()}
            {this.showInvJobinfo()}
            <InvDetail
              datas={this.state.infos}
              details={this.state.details}
            ></InvDetail>
          </div>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job };
};

export default connect(
  mapStateToProps,
  null
)(ViewInvoice);
