import React from "react";
import { Table, Dropdown, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";
import RxinfoOD from "./RxinfoOD";
import RxinfoOS from "./RxinfoOS";
import RxinfoOD2 from "./RxinfoOD2";
import RxinfoOS2 from "./RxinfoOS2";

class Rxinfo extends React.Component {
  state = {
    lensSide: "pair",
    validate: this.props.validate,
    od_axis_disabled: true,
    os_axis_disabled: true
  };

  setAxisDisabled = (side, val) => {
    side === "od" ?
      this.setState({ od_axis_disabled: val })
      :
      this.setState({ os_axis_disabled: val })
  }

  componentDidUpdate() {
    if (this.state.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate });
    }
  }

  showError = (val, rangemax, rangemin) => {
    let text = "";
    let returnValue = "";
    if (val > rangemax) text = "too hight";
    else if (val < rangemin) text = "too low";
    else text = "";

    if (text !== "") {
      returnValue = (
        <Label color="red" pointing>
          {text}
        </Label>
      );
    }

    return returnValue;
  };

  copyOdToOs = vFr => {
    const vTo = vFr === "od" ? "os" : "od";
    const copyItems = [
      "_sph",
      "_cyl",
      "_axis",
      "_add",
      "_seg",
      "_oc",
      "_base",
      "_pd",
      "_npd",
      "_thk"
    ];
    this.state.lensSide === "pair" &&
      copyItems.map(item =>
        this.props.setRedux(vTo + item, this.props[vFr + item])
      );
  };

  clearRxinfo = side => {
    const sd = side === "Right Only" ? "os" : side === "Left Only" ? "od" : "";
    const copyItems = [
      "_sph",
      "_cyl",
      "_axis",
      "_add",
      "_seg",
      "_oc",
      "_base",
      "_pd",
      "_npd",
      "_thk"
    ];
    side !== "pair" &&
      copyItems.map(item => this.props.setRedux(sd + item, ""));
  };

  renderLensSide = () => {
    const { lensSide, validate } = this.state;

    if (lensSide === "rightonly") return <RxinfoOD validate={validate} setAxisDisabled={this.setAxisDisabled} axisDisabled={this.state.od_axis_disabled} />;
    else if (lensSide === "leftonly") return <RxinfoOS validate={validate} setAxisDisabled={this.setAxisDisabled} axisDisabled={this.state.os_axis_disabled} />;
    else
      return (
        <>
          <RxinfoOD validate={validate} setAxisDisabled={this.setAxisDisabled} axisDisabled={this.state.od_axis_disabled} />
          <RxinfoOS validate={validate} setAxisDisabled={this.setAxisDisabled} axisDisabled={this.state.os_axis_disabled} />
        </>
      );
  };

  renderLensSide2 = () => {
    const { lensSide } = this.state;

    if (lensSide === "rightonly") return <RxinfoOD2 />;
    else if (lensSide === "leftonly") return <RxinfoOS2 />;
    else
      return (
        <>
          <RxinfoOD2 />
          <RxinfoOS2 />
        </>
      );
  };

  render() {
    const lensSide = [
      { key: 0, text: "Pair", value: "pair" },
      { key: 1, text: "OD Only", value: "rightonly" },
      { key: 2, text: "OS Only", value: "leftonly" }
    ];

    return (
      <div className="ui form">
        <div className="sixteen wide field">
          <label className="ui header">Patient Prescription Information</label>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="one wide">
                  <Dropdown
                    className="ui compact"
                    selection
                    options={lensSide}
                    name="lensSide"
                    defaultValue={"pair"}
                    onChange={(e, d) => {
                      this.props.setRedux(d.name, d.value);
                      this.setState({ lensSide: d.value });
                      this.clearRxinfo(d.value);
                    }}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui mini header">Sph</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">Cyl</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">Axis</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">Add</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">SegHt</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">OC</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">Base</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">PD</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">NPD</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">
                  <h5 className="ui header">Thk</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="one wide">                  
                </Table.HeaderCell>                
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderLensSide()}</Table.Body>
          </Table>

          {/*------------------------------------ secound row ---------------------------------------*/}
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="one wide" />
                <Table.HeaderCell className="two wide">
                  <h5 className="ui header">Prism H</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="two wide">
                  <h5 className="ui header">Prism V</h5>
                </Table.HeaderCell>
                <Table.HeaderCell className="seven wide" />
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderLensSide2()}</Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(mapStateToProps, { setRedux })(Rxinfo);
