import React from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import italeeApi from "../../api/italeeApi";
import TextField from "@material-ui/core/TextField";

class ShippingAddress extends React.Component {
  state = {
    shipList: [{ value: "" }],
    err_shipName: false,
    err_shipAddress: false,
    err_shipCity: false,
    err_shipZip: false,
    err_shipNum: false,
    validate: this.props.validate
  };
  _isMounted = false;

  renderShipAddr = (value, shipList) => {
    //this.props.handleDropDown(name, value);
    const result = shipList.find(i => i.value === value);
    if (result) {
      this.props.handleDropDown("shipCity", result.city);
      this.props.handleDropDown("shipAddress", result.addr);
      this.props.handleDropDown("shipState", result.sta);
      this.props.handleDropDown("shipZip", result.zip);
      this.props.handleDropDown("shipName", result.name);
      this.props.handleDropDown("shipNum", result.shipcode);
    }
  };

  componentDidUpdate() {
    const { validate } = this.props;

    if (this.state.validate !== validate) {
      //reset error field
      this.setState({
        err_shipName: false,
        err_shipAddress: false,
        err_shipCity: false,
        err_shipNum: false,
        err_shipZip: false
      });
      //set error field according to submit button
      validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate });
    }
    //console.log(this.props.validateField);
  }

  componentDidMount() {
    this._isMounted = true;
    const dropship = {
      key: 0,
      text: "DROPSHIP",
      value: "DROP",
      city: "",
      addr: "",
      sta: "State",
      name: "",
      zip: "",
      shipcode: "DROP"
    };
    if (this.props.acctNum) {
      italeeApi
        .get(`/LoginAuth/GetShipAddr?acctnum=${this.props.acctNum}`)
        .then(response => {
          if (this._isMounted) {
            this.setState({ shipList: response.data });
            this.setState({ shipList: [...this.state.shipList, dropship] });
          }
        })
        .catch(() => {
          console.log("Error...");
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // const shippingLocation = [
    //   { key: 1, text: "RIOCV", value: "RIOCV" },
    //   { key: 2, text: "1050", value: "1050" },
    //   { key: 3, text: "1009", value: "1009" },
    //   { key: 4, text: "7062", value: "7062" },
    //   { key: 5, text: "TLAB", value: "TLAB" },
    //   { key: 6, text: "DROPSHIP", value: "DROP" }
    // ];

    const stateList = [
      { key: 0, value: "", name: "State" },
      { key: 1, value: "AL", name: "Alabama" },
      { key: 2, value: "AK", name: "Alaska" },
      { key: 3, value: "AZ", name: "Arizona" },
      { key: 4, value: "AR", name: "Arkansas" },
      { key: 5, value: "CA", name: "California" },
      { key: 6, value: "CO", name: "Colorado" },
      { key: 7, value: "CT", name: "Connecticut" },
      { key: 8, value: "DE", name: "Delaware" },
      { key: 9, value: "DC", name: "District Of Columbia" },
      { key: 10, value: "FL", name: "Florida" },
      { key: 11, value: "GA", name: "Georgia" },
      { key: 12, value: "HI", name: "Hawaii" },
      { key: 13, value: "ID", name: "Idaho" },
      { key: 14, value: "IL", name: "Illinois" },
      { key: 15, value: "IN", name: "Indiana" },
      { key: 16, value: "IA", name: "Iowa" },
      { key: 17, value: "KS", name: "Kansas" },
      { key: 18, value: "KY", name: "Kentucky" },
      { key: 19, value: "LA", name: "Louisiana" },
      { key: 20, value: "ME", name: "Maine" },
      { key: 21, value: "MD", name: "Maryland" },
      { key: 22, value: "MA", name: "Massachusetts" },
      { key: 23, value: "MI", name: "Michigan" },
      { key: 24, value: "MN", name: "Minnesota" },
      { key: 25, value: "MS", name: "Mississippi" },
      { key: 26, value: "MO", name: "Missouri" },
      { key: 27, value: "MT", name: "Montana" },
      { key: 28, value: "NE", name: "Nebraska" },
      { key: 29, value: "NV", name: "Nevada" },
      { key: 30, value: "NH", name: "New Hampshire" },
      { key: 31, value: "NJ", name: "New Jersey" },
      { key: 32, value: "NM", name: "New Mexico" },
      { key: 33, value: "NY", name: "New York" },
      { key: 34, value: "NC", name: "North Carolina" },
      { key: 35, value: "ND", name: "North Dakota" },
      { key: 36, value: "OH", name: "Ohio" },
      { key: 37, value: "OK", name: "Oklahoma" },
      { key: 38, value: "OR", name: "Oregon" },
      { key: 39, value: "PA", name: "Pennsylvania" },
      { key: 40, value: "RI", name: "Rhode Island" },
      { key: 41, value: "SC", name: "South Carolina" },
      { key: 42, value: "SD", name: "South Dakota" },
      { key: 43, value: "TN", name: "Tennessee" },
      { key: 44, value: "TX", name: "Texas" },
      { key: 45, value: "UT", name: "Utah" },
      { key: 46, value: "VT", name: "Vermont" },
      { key: 47, value: "VA", name: "Virginia" },
      { key: 48, value: "WA", name: "Washington" },
      { key: 49, value: "WV", name: "West Virginia" },
      { key: 50, value: "WI", name: "Wisconsin" },
      { key: 51, value: "WY", name: "Wyoming" }
    ];
    const validate = this.props.ship.shipInfo;
    return (
      <form className="ui form">
        <div className="field">
          <label>Shipping Location</label>
          <div className="field">
            <div className="field">
              <Dropdown
                options={this.state.shipList}
                placeholder="Select Shipping Location"
                selection
                fluid
                name="shipNum"
                value={this.props.ship.shipInfo.shipNum}
                onChange={async (e, { value, name }) => {
                  this.renderShipAddr(value, this.state.shipList);
                  (await validate.shipNum) !== "" &&
                    this.setState({ err_shipNum: false });
                }}
                error={this.state.err_shipNum}
              />
            </div>
          </div>
        </div>

        <div className="field">
          <label>Name</label>
          <div className="field">
            <div className="field">
              <TextField
                type="text"
                name="shipName"
                placeholder="Name"
                value={this.props.ship.shipInfo.shipName || ""}
                onChange={async e => {
                  await this.props.setRedux(e.target.name, e.target.value);
                  (await validate.shipName) !== "" &&
                    this.setState({ err_shipName: false });
                }}
                fullWidth
                variant="outlined"
                error={this.state.err_shipName}
                readOnly={
                  this.props.ship.shipInfo.shipNum === "DROP" ? false : true
                }
              />
            </div>
          </div>
        </div>

        <div className="field">
          <label>Shipping Address</label>
          <div className="field">
            <div className="field">
              <TextField
                type="text"
                name="shipAddress"
                placeholder="Address"
                value={this.props.ship.shipInfo.shipAddress || ""}
                onChange={async e => {
                  await this.props.setRedux(e.target.name, e.target.value);
                  (await validate.shipAddress) !== "" &&
                    this.setState({ err_shipAddress: false });
                }}
                fullWidth
                variant="outlined"
                error={this.state.err_shipAddress}
                readOnly={
                  this.props.ship.shipInfo.shipNum === "DROP" ? false : true
                }
              />
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="eight wide field">
            <label>City</label>
            <TextField
              type="text"
              name="shipCity"
              placeholder="City"
              value={this.props.ship.shipInfo.shipCity || ""}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await validate.shipCity) !== "" &&
                  this.setState({ err_shipCity: false });
              }}
              fullWidth
              variant="outlined"
              error={this.state.err_shipCity}
              readOnly={
                this.props.ship.shipInfo.shipNum === "DROP" ? false : true
              }
            />
          </div>
          <div className="four wide field">
            <label>State</label>
            <select
              className="ui fluid dropdown"
              value={this.props.ship.shipInfo.shipState}
              disabled={
                this.props.ship.shipInfo.shipNum === "DROP" ? false : true
              }
              name="shipState"
              onChange={e => this.props.handleText(e.target)}
            >
              {stateList.map(st => {
                return (
                  <option value={st.value} key={st.key}>
                    {st.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="four wide field">
            <label>Postal Code</label>
            <TextField
              type="text"
              name="shipZip"
              placeholder="Postal Code"
              value={this.props.ship.shipInfo.shipZip || ""}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await validate.shipZip) !== "" &&
                  this.setState({ err_shipZip: false });
              }}
              fullWidth
              variant="outlined"
              error={this.state.err_shipZip}
              readOnly={
                this.props.ship.shipInfo.shipNum === "DROP" ? false : true
              }
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return { ...state, acctNum: state.job.jobInfo.acctNum };
};

const mapDispatchToProps = dispatch => {
  return {
    handleDropDown: (name, val) => dispatch(actions.setShipDropDown(name, val)),
    handleText: val => dispatch(actions.setShipText(val)),
    setRedux: (name, val) => dispatch(actions.setReduxShip(name, val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
