import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import InvHeader from "./../subcomponent/InvHeader";
import InvRxInfo from "./../subcomponent/InvRxInfo";
import InvJobInfo from "./../subcomponent/InvJobInfo";
import InvDetailcp from "./../subcomponent/invDetailCp";
import InvFrame from "./../subcomponent/InvFrame";
import InvShapeView from "./../subcomponent/InvShapeView";
//import ReactToPrint from "react-to-print";

class ViewPreOrder extends React.Component {
    state = { open: false, message: "test", infos: {}, details: [], toPrint: "" };

    show = () => this.setState({ open: true });
    close = () => this.setState({ open: false });

    async componentDidUpdate() {
        if (this.props.open !== this.state.open) {
            this.setState({ open: this.props.open });            
            this.getInvoiceDetail();
            let { message } = this.props.result;
            this.setState({ message });
        }

    }

    getInvoiceDetail = async () => {

        const job = this.props.job.jobInfo;
        const ship = this.props.ship.shipInfo;
        let today = new Date();
        let showToday = (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();
        
        let _polish = job.lensPolish ? "POLISH, " : "";
        let _spolish = job.lensSoftPolish ? "SOFT POLISH, " : "";
        let _uv = job.lensUV ? "UV400, " : "";
        let _cgroove = job.lensGroove ? "COLOR GROOVE, " : "";
        let lensEdgeTreatment = _polish + _spolish + _uv + _cgroove ;
        lensEdgeTreatment = lensEdgeTreatment.substring(0,lensEdgeTreatment.length - 2);

       await  this.setState({
            "infos":
            {
                "ordDate": showToday,
                "invnum": null,
                "jobid": 0,
                "ponum": "",
                "jobType": job.jobType,
                "patientName": job.patientName + " " + job.patientLastName,
                "instruction": job.jobInstruction + "|" + job.pofInformation + "|" + job.lensEngraving,
                "cfComment": job.cfComment,
                "billAcctnum": job.acctNum,
                "billAcctName": job.acctName,
                "billAddress": job.acctAddress,
                "billAddrCity": job.acctCity,
                "billAddrState": job.acctState,
                "billAddrZip": job.acctZip,
                "shipnum": ship.shipNum,
                "shipAcctName": ship.shipName,
                "shipAddress": ship.shipAddress,
                "shipAddrCity": ship.shipCity,
                "shipAddrState": ship.shipState,
                "shipAddrZip": ship.shipZip,
                "frameJobType": job.jobType,
                "lensJobType": job.lensSide,
                "odSph": job.od_sph,
                "odCyl": job.od_cyl,
                "odAxis": job.od_axis,
                "odPrismH": job.od_prismh + ' ' + job.od_prismh_d, 
                "odPrismV": job.od_prismv + ' ' + job.od_prismv_d, 
                "odBase": job.od_base,
                "odAdd": job.od_add,
                "odSegHt": job.od_seg,
                "odPD": job.od_pd,
                "odNPD": job.od_npd,
                "odThk": job.od_thk,
                "odWidth": "",
                "osSph": job.os_sph,
                "osCyl": job.os_cyl,
                "osAxis": job.os_axis,
                "osPrismH": job.os_prismh + ' ' + job.os_prismh_d, 
                "osPrismV": job.os_prismv + ' ' + job.os_prismv_d, 
                "osBase": job.os_base,
                "osAdd": job.os_add,
                "osSegHt": job.os_seg,
                "osPD": job.os_pd,
                "osNPD": job.os_npd,
                "osThk": job.os_thk,
                "osWidth": "",
                "valA": job.aValue,
                "valB": job.bValue,
                "valED": job.edValue,
                "valDBL": job.dblValue,
                "r_odSph": "",
                "r_odCyl": "",
                "r_odAxis": "",
                "r_odAdd": "",
                "r_osSph": "",
                "r_osCyl": "",
                "r_osAxis": "",
                "r_osAdd": "",
                "osLensName": job.lensType,
                "osLensMaterial": job.lensMaterialText,
                "osLensPrice": "",
                "osLensColor": job.lensColor,
                "odLensName": job.lensType,
                "odLensMaterial": job.lensMaterialText,
                "odLensPrice": "",
                "odLensColor": job.lensColor,
                "subTotal": 0.0,
                "taxableAmount": 0.0,
                "taxAmount": 0.0,
                "grandTotal": 0.0,
                "taxRate": 0.0,
                "discount": 0.0,
                "shipPrice": 0.0,
                "orderUserId": job.loginAcct,
                "shapeName": job.lensShape,
                "eyeSize": job.lensEyesize,
                "tintOther": job.lensTintDesc,
                "tint": job.lensTint,
                "lensEdgeType": job.lensEdgeType,
                "edgeTreatment": lensEdgeTreatment,
                "specialMemo": job.specialMemo,

                "lensCoating" : job.lensCoatingText,
                "lensStyle" : job.lensStyleText,
                "lensMaterial" : job.lensMaterialText
            },
            "details": [
                {
                    "key": 1,
                    "itemDesc": "[OD] IPALPOL",
                    "itemPrice": 0.0,
                    "itemQty": 1,
                    "itemTaxable": "y"
                }
            ]

        })

        //await this.setState({
        //   infos: data.invoiceGeneralInfos,
        //    details: data.invoiceChageLists
        //});

    };

    showInvJobinfo = () => {
        const toComp = this.state.infos.jobType;
        if (toComp === "UNCUT")
            return <InvJobInfo datas={this.state.infos} uncut={true}></InvJobInfo>;
        else if (toComp === "POF")
            return <InvJobInfo datas={this.state.infos} uncut={false}></InvJobInfo>;
        else return <div></div>;
    };

    showRxInfo = () => {
        const toComp = this.state.infos.jobType;
        if (toComp === "UNCUT" || toComp === "POF" || toComp === "FULL")
            return (
                <div>
                    <InvFrame datas={this.state.infos} />
                    <InvRxInfo datas={this.state.infos}></InvRxInfo>
                </div>
            );
        else return <div></div>;
    };

    showMessage = (message, status) => {
        return status ?
            (
                <div className='ui positive icon message'>
                    <i className='mini info circle icon'></i>
                    <div className='content'>{message}</div>
                </div>
            ) :
            (
                <div className='ui negative icon message'>
                    <i className='mini times circle outline icon'></i>
                    <div className='content'>{message}</div>
                </div>
            )

    }

    render() {
        const inlineStyle = {
            modal: {
                marginTop: "0px !important",
                marginLeft: "0px",
                marginRight: "auto"
            }
        };



        const { message } = this.state;
        const { onClose, onSubmit, status } = this.props.result;

        return (
            <div>
                <Modal
                    open={this.state.open}
                    dimmer={"inverted"}
                    onClose={this.props.closeInvoice}
                    centered={true}
                    style={inlineStyle.modal}
                    size={"large"}
                    closeOnEscape={true}
                >
                    <Modal.Content>
                        <div ref={el => (this.componentRef = el)}>

                            <InvHeader datas={this.state.infos}></InvHeader>
                            {this.showRxInfo()}
                            <InvShapeView shapeName={this.state.infos.shapeName} eyeSize={this.state.infos.eyeSize} />
                            {this.showInvJobinfo()}
                            <InvDetailcp
                                datas={this.state.infos}
                                details={this.state.details}
                                mode={"view"}
                            ></InvDetailcp>

                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        {this.showMessage(message, status)}
                        {/*
                        <ReactToPrint
                            trigger={() => { return this.state.infos ? <div className="ui orange button">Print Page</div> : <div className="ui red loading button"></div> }}
                            content={() => this.componentRef}
                            pageStyle="@page {size:8in 16in;}"
                        /> 
                        */}
                        <Button negative onClick={onClose}>
                            Review Again.
                        </Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Yes, All information is correct.'
                            onClick={onSubmit}
                            disabled={status ? false : true}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return { ...state, ownProps };
};

export default connect(
    mapStateToProps,
    null
)(ViewPreOrder);
