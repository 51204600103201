import "./App.css";
import React, { Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "../history";
import PlaceOrder from "./subitems/PlaceOrder";
import ShippingStatus from "./subitems/ShippingStatus";
import Statements from "./subitems/Statements";
import Home from "./subitems/Home";
import Invoices from "./subitems/Invoices";
import Jobstatus from "./subitems/Jobstatus";
import PofOrder from "./subitems/PofOrder";
import LogIn from "./subitems/LogIn";
import Register from "./subitems/Register";

import CacheBuster from "./CacheBuster";

class App extends React.Component {
  componentDidMount() {
    const ele = document.getElementById("loading");
    if (ele) {
      setTimeout(() => {
        ele.outerHTML = "";
      }, 0);
    }
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <Router history={history}>
              <Fragment>
                <Switch>
                  <Route path="/" exact component={LogIn} />
                  <Route path="/home" exact component={Home} />
                  <Route path="/placeorder" exact component={PlaceOrder} />
                  <Route path="/poforder" exact component={PofOrder} />
                  <Route
                    path="/shippingstatus"
                    exact
                    component={ShippingStatus}
                  />
                  <Route path="/statements" exact component={Statements} />
                  <Route path="/invoices" exact component={Invoices} />
                  <Route path="/jobstatus" exact component={Jobstatus} />
                  <Route path="/register" exact component={Register} />
                </Switch>
              </Fragment>
            </Router>
          );
        }}
      </CacheBuster>
    );
  }
}

//   render() {
//     return (
//       <Router history={history}>
//         <Fragment>
//           <Switch>
//             <Route path="/" exact component={Home} />
//             <Route path="/placeorder" exact component={PlaceOrder} />
//             <Route path="/poforder" exact component={PofOrder} />
//             <Route path="/shippingstatus" exact component={ShippingStatus} />
//             <Route path="/statements" exact component={Statements} />
//             <Route path="/invoices" exact component={Invoices} />
//             <Route path="/jobstatus" exact component={Jobstatus} />
//             <Route path="/login" exact component={LogIn} />
//             <Route path="/register" exact component={Register} />
//           </Switch>
//         </Fragment>
//       </Router>
//     );
//   }
// }

export default App;
