import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class SearchBox extends React.Component {
  state = {
    patName: "",
    invNum: "",
    requestInfo: {},
    invType: "A"
  };

  async componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      (await this.setState({
        startDate: new Date(),
        endDate: new Date()
      }));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  handleTxtField = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSearch = async () => {
    await this.props.loadAll(
      this.dateConverter(this.state.startDate),
      this.dateConverter(this.state.endDate),
      this.emptyConverter(this.state.patName),
      this.emptyConverter(this.state.invNum),
      this.state.invType
    );
  };

  handleReset = () => {
    this.setState({
      patName: "",
      invNum: "",
      requestInfo: {},
      invType: "A",
      startDate: new Date(),
      endDate: new Date()
    });
  };

  dateConverter = date => {
    let currDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    return Date.parse(currDate);
  };

  emptyConverter = value => {
    return value ? value : "*";
  };

  render() {
    return (
      <div>
        <div className="ui form">
          <div className="two fields">
            <div className="field">
              <label>From</label>
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateFormat="MM/d/yyyy"
                onChange={this.handleStartDate}
              />
            </div>
            <div className="field">
              <label>To</label>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateFormat="MM/d/yyyy"
                onChange={this.handleEndDate}
              />
            </div>
          </div>

          <div className="two fields">
            <div className="field">
              <label>Patient Name</label>
              <input
                type="text"
                name="patName"
                placeholder="Patient Name"
                value={this.state.patName}
                onChange={this.handleTxtField}
              />
            </div>
            <div className="field">
              <label>Invoice Number</label>
              <input
                type="text"
                name="invNum"
                placeholder="Invoice Number"
                value={this.state.invNum}
                onChange={this.handleTxtField}
              />
            </div>
          </div>

          <div className="two fields">
            <div className="field">
              <label>Types</label>
              <select
                name="invType"
                onChange={this.handleTxtField}
                value={this.state.invType}
              >
                <option value="A">All Invoces</option>
                <option value="D">Debit Invoices</option>
                <option vaue="C">Credit Invoces</option>
              </select>
            </div>

            <div className="field">
              <div className="two fields">
                <div className="field">
                  <label>&nbsp;</label>
                  <button
                    className="ui button positive fluid"
                    onClick={this.handleReset}
                  >
                    RESET
                  </button>
                </div>
                <div className="field">
                  <label>&nbsp;</label>
                  <button
                    className="ui button primary fluid"
                    onClick={this.handleSearch}
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(mapStateToProps)(SearchBox);
