import React from "react";
import Header from "./../Header";
import Footer from "./../../Footer";
import DataTable from "./../subcomponent/DataTable";
import PatientName from "./../subcomponent/PatientName";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
import { connect } from "react-redux";
import italeeApi from "../../api/italeeApi";
import { Pagination } from "semantic-ui-react";

class ShippingStatus extends React.Component {
  _isMounted = false;
  state = {
    currentDate: new Date(),
    creditList: [],
    creditTotalRec: 1,
    debitList: [],
    debitTotalRec: 1,
    debitCurrPage: 1,
    creditCurrPage: 1,
    pageSize: 25
  };

  handleSelectDate = async date => {
    let currDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    let apiDate = Date.parse(currDate);
    await this.setState({ currentDate: date });
    await this.setState({ apiDate });
    this.loadAll(this.state.apiDate);
  };

  loadDebitList = async (apiDate, currPage = 1) => {
    const response = await italeeApi.get(
      `ShipStatus/GetShipStatus/D/${this.props.acctNum}/${apiDate}/${currPage}`
    );
    this.setState({ debitList: response.data });
  };

  loadDebitRecNum = async apiDate => {
    const response = await italeeApi.get(
      `ShipStatus/GetShipTotalRec/D/${this.props.acctNum}/${apiDate}`
    );
    this.setState({
      debitTotalRec: response.data[0].totalCount / this.state.pageSize
    });
  };

  loadCreditList = async (apiDate, currPage = 1) => {
    const response = await italeeApi.get(
      `ShipStatus/GetShipStatus/C/${this.props.acctNum}/${apiDate}/${currPage}`
    );
    this.setState({ creditList: response.data });
  };

  loadCreditRecNum = async apiDate => {
    const response = await italeeApi.get(
      `ShipStatus/GetShipTotalRec/C/${this.props.acctNum}/${apiDate}`
    );
    this.setState({
      creditTotalRec: response.data[0].totalCount / this.state.pageSize
    });
  };

  loadAll = (apiDate = this.state.apiDate) => {
    this.loadDebitList(apiDate, this.state.debitCurrPage);
    this.loadDebitRecNum(apiDate);
    this.loadCreditList(apiDate, this.state.creditCurrPage);
    this.loadCreditRecNum(apiDate);
  };

  async componentDidMount() {
    this._isMounted = true;
    var date = new Date(); //get now
    let currDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ); // remove time, get only date
    let apiDate = Date.parse(currDate);

    this._isMounted && (await this.setState({ apiDate }));

    this.loadAll(this.state.apiDate);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const tableTitles = [
      {
        key: 1,
        title: [
          { key: 1, data: "Invoice #" },
          { key: 2, data: "Ship Date" },
          { key: 3, data: "Patient Name" },
          { key: 4, data: "PO #" },
          { key: 5, data: "Tax Amount" },
          { key: 6, data: "Total Amount" },
          { key: 7, data: "Status" },
          { key: 8, data: "Tracking #" }
        ]
      }
    ];

    const tableFoot = [
      {
        key: 1,
        foot: [
          { key: 1, data: "" },
          { key: 2, data: "" },
          { key: 3, data: "" },
          { key: 4, data: "" },
          { key: 5, data: "" },
          { key: 6, data: "" },
          { key: 7, data: "" },
          { key: 8, data: "" }
        ]
      }
    ];

    return (
      <div>
        <Header currMenu="SHIPPEDJOBS" />
        <div className="ui main container">
          <div className="ui container">
            <h3 className="ui header">
              <i className="shipping fast icon" />
              <div className="content">Shipping Status</div>
            </h3>

            <div className="ui segment">
              <div className="ui form">
                <div className="two fields">
                  <div className="eight wide field">
                    <PatientName AllValueInc StatementRefresh={this.loadAll} />
                  </div>
                  <div className="eight wide field" />
                </div>
              </div>
              <div className="ui form">
                <div className="field">
                  <label>Date</label>
                  <DatePicker
                    selected={this.state.currentDate}
                    dateFormat="MM/dd/yyyy"
                    onChange={this.handleSelectDate}
                  />
                </div>
              </div>
              <div className="ui header small">Job List</div>
              <DataTable
                tableTitle={tableTitles}
                tableBody={this.state.debitList}
                tableFoot={tableFoot}
                tableColor={"green"}
              />
              <Pagination
                defaultActivePage={this.state.debitCurrPage}
                totalPages={this.state.debitTotalRec}
                onPageChange={async (e, data) => {
                  await this.loadDebitList(this.state.apiDate, data.activePage);
                }}
              />
              <div className="ui header small">Credit List</div>
              <DataTable
                tableTitle={tableTitles}
                tableBody={this.state.creditList}
                tableFoot={tableFoot}
                tableColor={"blue"}
              />
              <Pagination
                defaultActivePage={this.state.creditCurrPage}
                totalPages={this.state.creditTotalRec}
                onPageChange={async (e, data) => {
                  await this.loadCreditList(
                    this.state.apiDate,
                    data.activePage
                  );
                }}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(
  mapStateToProps,
  null
)(ShippingStatus);
