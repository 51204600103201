import React from "react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";
import { Table, Label, Dropdown } from "semantic-ui-react";
import NumberFormat from "react-number-format";

class RxinfoOD2 extends React.Component {
  copyOdToOs = vFr => {
    const vTo = vFr === "od" ? "os" : "od";
    const copyItems = [
      "_sph",
      "_cyl",
      "_axis",
      "_add",
      "_seg",
      "_oc",
      "_base",
      "_pd",
      "_npd",
      "_thk"
    ];
    copyItems.map(item =>
      this.props.setRedux(vTo + item, this.props[vFr + item])
    );
  };

  showError = (val, rangemax, rangemin) => {
    let text = "";
    let returnValue = "";
    if (val > rangemax) text = "too hight";
    else if (val < rangemin) text = "too low";
    else text = "";

    if (text !== "") {
      returnValue = (
        <Label color="red" pointing>
          {text}
        </Label>
      );
    }

    return returnValue;
  };

  render() {
    
    const prismHDirection = [
        { key: 0, text: "", value: "" },
        { key: 1, text: "OUT", value: "OUT" },
        { key: 2, text: "IN", value: "IN" }
      ];
  
      const prismVDirection = [
        { key: 0, text: "", value: "" },
        { key: 1, text: "UP", value: "UP" },
        { key: 2, text: "DN", value: "DN" }
      ];

    return (
      <Table.Row>
        <Table.Cell>
          <div className="center aligned">OD</div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui right input action">
            <NumberFormat
              name="od_prismh"
              value={this.props.od_prismh}
              onChange={e => this.props.setRedux(e.target.name, e.target.value)}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
            />
            <Dropdown
              className="ui compact"
              selection
              options={prismHDirection}
              value={this.props.od_prismh_d}
              name="od_prismh_d"
              onChange={(e, { value, name }) => {
                this.props.setRedux(name, value)
              }}              
            />
          </div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui right input action">
            <NumberFormat
              name="od_prismv"
              value={this.props.od_prismv}
              onChange={e => this.props.setRedux(e.target.name, e.target.value)}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
            />
            <Dropdown
              className="ui compact"
              selection
              options={prismVDirection}
              value={this.props.od_prismv_d}
              name="od_prismv_d"
              onChange={async (e, { value, name }) => {
                this.props.setRedux(name, value)
              }}              
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(
  mapStateToProps,
  { setRedux }
)(RxinfoOD2);
