import * as types from "../actions/actionTypes";

const initState = {
  jobInfo: {
    lensSide: "pair",
    lensStyle: "",
    lensDigital : 0,
    lensStyleText: "",
    lensMaterial: "",
    lensMaterialText: "",
    lensColor: null,
    lensShape: null,
    lensEyesize: null,
    lensTint: "",
    lensTintDesc: "",
    lensOption: "",
    lensType: "",
    lensKind: "",
    lensCoating: "",
    lensCoatingText: "",
    lensCoatingAval: "",
    lensEdgeType: "",
    lensPolish: false,
    lensSoftPolish: false,
    lensCutting: false,
    lensGroove: false,
    lensUV: false,
    jobType: null,
    lsStyle: "A",
    lsType: "A",
    powermax: 30,
    powermin: -30,
    cylmax: 8,
    addmax: 4,

    od_sph: null,
    od_cyl: null,
    od_axis: null,
    od_add: "",
    od_seg: null,
    od_oc: null,
    od_prismh: "",
    od_prismh_d: "",
    od_prismv: "",
    od_prismv_d: "",
    od_base: null,
    od_pd: null,
    od_npd: null,
    od_thk: null,

    os_sph: null,
    os_cyl: null,
    os_axis: null,
    os_add: null,
    os_seg: null,
    os_oc: null,
    os_prismh: "",
    os_prismh_d: "",
    os_prismv: "",
    os_prismv_d: "",
    os_base: null,
    os_pd: null,
    os_npd: null,
    os_thk: null,

    patientName: "",
    patientLastName: "",

    acctNum: null,
    acctName: null,
    acctAddress: null,
    acctCity: null,
    acctZip: null,
    acctState: null,
    acctCif: null,

    aValue: "",
    bValue: "",
    edValue: "",
    dblValue: "",

    panto: "",
    wrap: "",
    vertexOD: "",
    vertexOS: "",

    pofInformation: "",

    authStatus: null,
    loginAcct: "",

    cfComment: "",
    jobInstruction: "",
    lensEngraving: "",
    specialMemo : "" ,

    currentMenu: "HOME",
    Order: {
      lensAmount: 0,
      coatingAmount: 0,
      tintingAmount: 0,
      edgeAmount: 0
    },
    
    resetCode : 0
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.RXINFO_UPDATE:
      return {
        // ...state,
        jobInfo: { ...state.jobInfo, [action.load.name]: action.load.value }
      };

    case types.DROPDOWN_UPDATE:
      return {
        ...state,
        jobInfo: { ...state.jobInfo, [action.load.stateName]: action.load.val }
      };

    case types.LOG_IN:
      return {
        ...state,
        jobInfo: {
          ...state.jobInfo,
          acctNum: action.load.macct,
          loginAcct: action.load.userid,
          authStatus: "accepted"
        }
      };

    case types.LOG_OUT:
      return initState;

    case types.UPDATE_ACCTINFO:
      return {
        ...state,
        jobInfo: {
          ...state.jobInfo,
          acctName: action.load.acctname,
          acctAddress:
            action.load.acctaddresS1 + " " + action.load.acctaddresS2,
          acctCity: action.load.acctaddresscity,
          acctZip: action.load.acctpostalcode,
          acctState: action.load.acctaddressstate,
          acctCif: action.load.acctgroup,
          authStatus: "accepted"
        }
      };

    case types.SET_REDUX:
      return {
        ...state,
        jobInfo: {
          ...state.jobInfo,
          [action.name]: action.load
        }
      };

    case types.SET_REDUX_ORDER:
      return {
        ...state,
        jobInfo: {
          ...state.jobInfo,
          Order: {
            ...state.jobInfo.Order,
            [action.name]: action.load
          }
        }
      };

    case types.RESET:
      return {
        ...state,
        jobInfo: {
          ...state.jobInfo,
          lensStyle: "pair",
          lensDigital : 0,
          lensStyleText: "",
          lensMaterial: "",
          lensMaterialText: "",
          lensColor: null,
          lensShape: null,
          lensEyesize: null,
          lensTint: "",
          lensTintDesc: "",
          lensOption: "",
          lensType: "",
          lensCoating: "",
          lensCoatingText: "",
          lensCoatingAval: "",
          lensEdgeType: "",
          lensPolish: false,
          lensSoftPolish: false,
          lensCutting: false,
          lensGroove: false,
          lensUV: false,
          lsStyle: "A",
          lsType: "A",
          lensKind: "",
          powermax: 30,
          powermin: -30,
          cylmax: 8,
          addmax: 4,

          od_sph: null,
          od_cyl: null,
          od_axis: null,
          od_add: "",
          od_seg: null,
          od_oc: null,
          od_prismh: "",
          od_prismh_d: "",
          od_prismv: "",
          od_prismv_d: "",
          od_base: null,
          od_pd: null,
          od_npd: null,
          od_thk: null,

          os_sph: null,
          os_cyl: null,
          os_axis: null,
          os_add: null,
          os_seg: null,
          os_oc: null,
          os_prismh: "",
          os_prismh_d: "",
          os_prismv: "",
          os_prismv_d: "",
          os_base: null,
          os_pd: null,
          os_npd: null,
          os_thk: null,

          patientName: "",
          patientLastName: "",
          cfComment: "",
          jobInstruction: "",
          lensEngraving: "",

          aValue: "",
          bValue: "",
          edValue: "",
          dblValue: "",

          panto: "",
          wrap: "",
          vertexOD: "",
          vertexOS: "",

          pofInformation: "",
          specialMemo : "" ,

          Order: {
            lensAmount: 0,
            coatingAmount: 0,
            tintingAmount: 0,
            edgeAmount: 0
          }

        }
      };

    default:
      return state;
  }
};
