import React from "react";
import { Dropdown, Checkbox, Grid, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";

class OtherServices extends React.Component {
  state = {
    lensCutting: false,
    lensUV: false,
    lensGroove: false,
    lensPolish: false,
    lensSoftPolish: false,
    value: "",
    disableColorGroove: false,
    edgeType: [
      { key: 0, text: "", value: "" },
      { key: 1, text: "Metal Frame", value: "METAL" },
      { key: 2, text: "Zyle Frame", value: "ZYLE" },
      { key: 3, text: "Drill Mount", value: "DRILL" },
      { key: 4, text: "Groove Mount", value: "GROOVE" },
      { key: 5, text: "Safety", value: "" }
    ],
    err_lensCoating: false,
    err_lensEdgeType: false,
    validate: this.props.validate
  };

  componentDidUpdate() {
    if (this.props.disableColorGroove !== this.state.disableColorGroove) {
      this.setState({ disableColorGroove: this.props.disableColorGroove });
      this.props.disableColorGroove && this.setState({ lensGroove: false });
    }

    const { validate } = this.props;

    if (this.state.validate !== validate) {
      //reset error field
      this.setState({
        err_lensCoating: false,
        err_lensEdgeType: false
      });
      //set error field according to submit button
      validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate });
    }
  }

  handleChange = async (e, { value, name }) => {
    this.setState({ value });
    await this.props.setRedux(name, value);
  };
  handleCheckBox = async (e, { value, name }) => {

    this.setState(prevState => ({ [name]: !prevState[name] }));
    await this.props.setRedux(name, !this.state[name]);

    if (name === "lensPolish") {
      this.setState({ lensSoftPolish: false });
      await this.props.setRedux("lensSoftPolish", false);
    }
    if (name === "lensSoftPolish") {
      this.setState({ lensPolish: false });
      await this.props.setRedux("lensPolish", false);
    }
    if (name === "lensGroove" && this.props.lensGroove) {
      await this.props.setRedux("lensEdgeType", "GROOVE");
    }

    await this.props.amountUpdate();
  };

  renderEdgeTreatment = () => {
    return (
      <div className='fields'>
        <div className='sixteen wide field'>
          <Grid columns='equal' padded>
            <Grid.Row>
              <Grid.Column>
                <h5>Edge Treatment</h5>
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  label='Edge Polish'
                  name='lensPolish'
                  checked={this.props.lensPolish}
                  onChange={this.handleCheckBox}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  label='Soft Edge Polish'
                  name='lensSoftPolish'
                  checked={this.props.lensSoftPolish}
                  onChange={this.handleCheckBox}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  label='Color Groove'
                  name='lensGroove'
                  checked={this.props.lensGroove}
                  onChange={this.handleCheckBox}
                  disabled={this.state.disableColorGroove}
                />
              </Grid.Column>
              <Grid.Column>
                <Checkbox
                  label='U.V. Coating'
                  name='lensUV'
                  checked={this.props.lensUV}
                  onChange={this.handleCheckBox}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  };

  renderEdgeTypeOrLaserEngraving = val => {
    if (val === "edge") {
      return (
        <div className='six wide field'>
          <label>Edge Type</label>
          <Dropdown
            selection
            options={this.state.edgeType}
            fluid
            placeholder='Select Edge Type'
            name='lensEdgeType'
            value={this.props.lensEdgeType}
            onChange={async (e, { value, name }) => {
              await this.props.setRedux(name, value);
              (await this.props.lensCoating) !== "" &&
                this.setState({ err_lensEdgeType: false });
            }}
            error={this.state.err_lensEdgeType}
          />{" "}
        </div>
      );
    } else if (val === "laserEng") {
      return (
        <div className='six wide field'>
          <label>Laser Engraving</label>
          <input
            name='lensEngraving'
            placeholder='Note for Engraving'
            onChange={({ target }) => {
              this.props.setRedux([target.name], target.value);
            }}
          />
        </div>
      );
    }
    else {
      return (<div></div>);
    }
  };

  engravingNotice = val => {
    if (val === "laserEng") {
      return (
        <div className='fields'>
          <div className='sixteen wide field'>
            <div className='ui brown icon message'>
              <i className='mini warning circle icon'></i>
              <div className='content'>
                Laser Engraving : Laser Engraving can be done on Polycarbonate
                lenses, but the engraving cannot be colored in.
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  arWidthSelector = val => {
    return val !== "laserEng" ? "sixteen" : "ten"
  }

  jobInstTitle = val => {
    return val === "UNCUT" ? <h5>Job Instructions</h5> : <h5>Job and Shape Instructions</h5>
  }

  jobInstruction = () => {
    return (
      <div className='fields'>
            <div className='sixteen wide field'>
              <Grid columns={2} padded>
                <Grid.Row>
                  <Grid.Column width={2}>
                    {this.jobInstTitle(this.props.jobType)}
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <textarea
                      type="text"
                      rows="3"
                      spellCheck="false"
                      name='jobInstruction'
                      placeholder='Enter Instruction for Coating, Edge Treatment, Color Groove, etc...'
                      value={this.props.jobInstruction}
                      onChange={({ target }) => {
                        this.setState({ [target.name]: target.value });
                        this.props.setRedux(target.name, target.value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
    )
  }

  render() {
    // const arCoating = [
    //   { key: 0, text: "No AR Coating", value: "none" },
    //   { key: 1, text: "Italee AR", value: "polish" },
    //   { key: 2, text: "Crizal Alize", value: "spolish" },
    //   { key: 3, text: "Italee SET", value: "roll" }
    // ];
    const arWidth = this.arWidthSelector(this.props.EdgeOrLaser);

    return (
      <div>
        <Form className='ui form'>
          <div className='fields'>
            <div className={`${arWidth} wide field`}>
              <label>Anti Reflection Coating</label>
              <Dropdown
                selection
                fluid
                search
                value={this.props.lensCoating}
                name='lensCoating'
                options={this.props.coatings}
                onChange={async (e, { value, name, options }) => {
                  let text = options.find(e => e.value === value).text;
                  await this.props.setRedux(name, value);
                  await this.props.setRedux("lensCoatingText", text);
                  (await this.props.lensCoating) !== "" &&
                    this.setState({ err_lensCoating: false });
                  this.props.amountUpdate();
                }}
                placeholder='Type Coating Name to Search'
                error={this.state.err_lensCoating}
              />
            </div>
            {this.renderEdgeTypeOrLaserEngraving(this.props.EdgeOrLaser)}
          </div>

          {this.engravingNotice(this.props.EdgeOrLaser)}
          {this.props.EdgeTreat && this.renderEdgeTreatment()}
          {this.props.jobType === "POF" && this.jobInstruction()}          
        </Form> 
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.job.jobInfo, ownProps };
};

export default connect(mapStateToProps, { setRedux })(OtherServices);
