import React from "react";
import italeeApi from "../../api/italeeApi";
import { connect } from "react-redux";
import { Modal, ModalHeader } from "semantic-ui-react";
import InvHeader from "./../subcomponent/InvHeader";
import InvRxInfo from "./../subcomponent/InvRxInfo";
import InvJobInfo from "./../subcomponent/InvJobInfo";
import InvDetail from "./../subcomponent/InvDetail";
import InvFrame from "./../subcomponent/InvFrame";
import InvShapeView from "./../subcomponent/InvShapeView";
import ReactToPrint from "react-to-print";


class ViewOrder extends React.Component {
  state = { open: false, ponum: this.props.ponum, infos: {}, details: [], toPrint: "" };


  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
      this.getInvoiceDetail();
    }
  }

  getInvoiceDetail = async () => {
    await this.setState({ ponum: this.props.ponum });
    const response = await italeeApi.get(
      `/invoices/ViewOrder/${this.state.ponum}`
    );
    await this.setState({ infos: response.data.invoiceGeneralInfos });
    await this.setState({ details: response.data.invoiceChageLists });
  };

  showInvJobinfo = () => {
    const toComp = this.state.infos.jobType;
    if (toComp === "UNCUT")
      return <InvJobInfo datas={this.state.infos} uncut={true}></InvJobInfo>;
    else if (toComp === "POF")
      return <InvJobInfo datas={this.state.infos} uncut={false}></InvJobInfo>;
    else return <div></div>;
  };

  showRxInfo = () => {
    const toComp = this.state.infos.jobType;
    if (toComp === "UNCUT" || toComp === "POF" || toComp === "FULL")
      return (
        <div>
          <InvFrame datas={this.state.infos} />
          <InvRxInfo datas={this.state.infos}></InvRxInfo>
        </div>
      );
    else return <div></div>;
  };

  render() {
    const inlineStyle = {
      modal: {
        marginTop: "0px !important",
        marginLeft: "0px",
        marginRight: "auto"
      }
    };


    return (
      <div>
        <Modal
          open={this.state.open}
          dimmer={"inverted"}
          onClose={this.props.closeInvoice}
          centered={true}
          style={inlineStyle.modal}
          size={"large"}
        >
          <ModalHeader>
            <h3>{this.props.title}</h3>
          </ModalHeader>
          <Modal.Content>
            <div ref={el => (this.componentRef = el)}>

              <InvHeader datas={this.state.infos} ></InvHeader>
              {this.showRxInfo()}
              <InvShapeView shapeName={this.state.infos.shapeName} eyeSize={this.state.infos.eyeSize} />
              {this.showInvJobinfo()}
              <InvDetail
                datas={this.state.infos}
                details={this.state.details}
                mode={"view"}
              ></InvDetail>

            </div>
          </Modal.Content>
          <Modal.Actions>
            <div className="ui green button"
              onClick={() => { this.props.closeInvoice() }}
            >Close</div>
            <ReactToPrint
              trigger={() => { return this.state.infos ? <div className="ui red button">Print</div> : <div className="ui red loading button"></div> }}
              content={() => this.componentRef}
              pageStyle="@page {size:8in 16in;}"
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return { ...state.job };
};

export default connect(
  mapStateToProps,
  null
)(ViewOrder);
