import React from "react";
import Header from "./../Header";
import Footer from "./../../Footer";
import content from "./Content";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../../css/style.css";

class Home extends React.Component {
  componentDidMount() {
    //console.log(React.version);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="ui main container">
          <div className="ui grid divided">
            <div className="sixteen wide column">
              <Slider autoplay={5000} duration={5000}>
                {content.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        background: `url('${item.image}') no-repeat center center`
                      }}
                    >
                      <div className="center">
                        <h1 className="text1">{item.title}</h1>
                        <p className="text1">{item.descripton}</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>

            <div className="five wide column">
              <h4 className="ui header">UNCOMPROMISED QUALITY AND SERVICE</h4>
              <p>
                In the early stages of Italee's development, the idea of
                offering a specialty rimless service for Kazuo Kawasaki evolved
                and lead to the creation of Italee Optics Inc. in 1995. Premised
                on the philosophy of delivering unsurpassed quality and service,
                Lab Italee provides the complete package by offering superior
                lab service on Italee’s unique frame collections.
              </p>
            </div>
            <div className="six wide column">
              <h4 className="ui header">ON-SITE CRIZAL/ALIZE COATING </h4>
              <p>
                In October 2003, Lab-Italee's state-of-the-art facility became
                the first independent lab to have onsite Crizal coating
                abilities. Shortly thereafter in March 2004, lab-Italee became
                the first west coast independent lab to offer onsite Alize
                coating services. In recognition of outstanding achievements,
                Lab-Italee was honored with Outstanding Performance in Varilux
                Sales in 2004, the Crizal Lab of the Year in 2004, and the
                Varilux Lab of the Year in 2005, at Essilor’s National Sales
                Meeting.
              </p>
            </div>
            <div className="five wide column">
              <h4 className="ui header">LAB-ITALEE</h4>
              <p>
                Lab-Italee's commitment to providing quality rimless services
                has expanded to include uncut lenses as well as full service
                orders for other frame collections. Lab-Italee understands that
                the qualities of the lenses are equally important as the design
                of the frame continues to be the driving force behind its
                success. Located in Los Angeles, CA, Italee Optics Inc. now
                serves eye care professionals and their patients throughout the
                United States.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
