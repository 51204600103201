export default [
    {
        title:"",
        descripton:"",
        image:"/sliderimages/1.png",
        user:"",
        userProfile:""
    },
    {
        title:"",
        descripton:"",
        image:"/sliderimages/2.png",
        user:"",
        userProfile:""
    },
    {
        title:"",
        descripton:"",
        image:"/sliderimages/3.png",
        user:"",
        userProfile:""
    }        

]
