import * as types from "./actionTypes";
import history from "../history";

export const setRx = val => {
  return {
    type: types.RXINFO_UPDATE,
    load: val
  };
};

export const setDropDown = (stateName, val) => {
  return {
    type: types.DROPDOWN_UPDATE,
    load: { stateName, val }
  };
};

export const setShipDropDown = (stateName, val) => {
  return {
    type: types.SHIP_DROPDOWN_UPDATE,
    load: { stateName, val }
  };
};

export const setText = val => {
  return {
    type: types.TEXT_UPDATE,
    load: val
  };
};

export const setShipText = val => {
  return {
    type: types.SHIP_TEXT_UPDATE,
    load: val
  };
};

export const getLogin = val => {
  return {
    type: types.LOG_IN,
    load: val
  };
};

export const updateAccountInfo = val => {
  return {
    type: types.UPDATE_ACCTINFO,
    load: val
  };
};

export const logOut = () => dispatch => {
   dispatch({
    type: types.LOG_OUT,
    load: null
  });
  history.push("/");
};

export const setRedux = (name , value) => dispatch => {
  dispatch({
    type: types.SET_REDUX,
    load: value,
    name: name
  })
};

export const setReduxOrder = (name , value) => dispatch => {
  dispatch({
    type: types.SET_REDUX_ORDER,
    load: value,
    name: name
  })
};

export const setReduxShip = (name , value) => dispatch => {
  dispatch({
    type: types.SET_REDUX_SHIP,
    load: value,
    name: name
  })
};

export const resetOrder = () => dispatch => {
  dispatch({
    type: types.RESET,
    load : null
  });
};