import "../dropdown.css";
import React from "react";
import { connect } from "react-redux";
import { Dropdown, Label } from "semantic-ui-react";
import * as actions from "../../actions";
import italeeApi from "../../api/italeeApi";
import { setRedux, setReduxOrder } from "./../../actions/index";
import _ from "lodash";

class LensDropDown extends React.Component {
  _isMounted = false;
  lensmaterial = [];
  state = {
    lenslist: [],
    lensmaterial: [{ key: 0, value: "", text: "" }],
    lensColorList: [{ key: 0, value: "", text: "" }],
    lensTintList: [
      { key: 0, value: "NOTINT", text: "No Tint", ltype: "" },
      { key: 1, value: "SOLID", text: "Solid Tint", ltype: "" },
      { key: 2, value: "GRAD", text: "Gradient Tint", ltype: "" },
      { key: 3, value: "GRAD D", text: "Double Gradient Tint", ltype: "" }
    ],
    lensType: [
      { key: 0, value: "A", text: "All" },
      { key: 1, value: "C", text: "Clear" },
      { key: 2, value: "P", text: "Polarized" },
      { key: 3, value: "T", text: "Transition" }
    ],
    lensKind: [
      { key: 0, value: "A", text: "All" },
      { key: 1, value: "S", text: "Single Vision" },
      { key: 2, value: "M", text: "Multifocal" },
      { key: 3, value: "P", text: "Progressive" }
    ],
    lensStyle: "",
    tintDisabled: false,
    tintValue: "NOTINT",
    err_lensColor: false,
    err_lensMaterial: false,
    err_lensStyle: false,
    validate: this.props.validate
  };

  mountLensMaterial = vallensstyle => {
    let lensstyle = vallensstyle
    italeeApi
      .get(`/lens/mate?ls=${lensstyle}`)
      .then(response => {
        this.setState({ lensmaterial: response.data });
        const temp = _.find(response.data, item => {
          return item.value === this.props.jobInfo.lensMaterial;
        });
        if (!temp) {
          this.props.setRedux("lensMaterial", "");
        }
      })
      .catch(() => {
        console.log("Error fetch material");
      });
  };

  mountLensColor = (valstyle, material) => {
    let style = valstyle;
    if (material === null || material === "") {
      this.setState({ lensColorList: [{ key: 0, value: "", text: "" }] });
      return null;
    }
    italeeApi
      .get(`/lens/Lcolor?ls=${style}&mt=${material}`)
      .then(response => {
        this.setState({ lensColorList: response.data });
        const temp = _.find(response.data, item => {
          return item.value === this.props.jobInfo.lensColor;
        });

        if (!temp) {
          this.props.setRedux("lensColor", "");
        }

        //console.log(this.state.lensColorList);
      })
      .catch(() => {
        console.log("Error fetching color");
      });
  };

  mountLens = async () => {
    await this.setState({
      lensMaterial: [],
      lensColorList: [{ key: 0, value: "", text: "" }]
    });
    const { lsType, lsStyle } = this.props.jobInfo;
    const response = await italeeApi.get(
      `/lens/GetTbLens/${lsType}/${lsStyle}`
    );
    //console.log(response.data);
    if (this._isMounted) this.setState({ lenslist: response.data });
  };


  retailLensShapeBlock = val => {
    const onChange = (e, val, name) => {
      this.props.handleOnChange(name, val);
    };

    return (
      <div className='fields'>
        <div className='two wide field'>
          <label className='ui header'>Lens Shape</label>
          <div className="ui action input">
            <input
              type='text'
              name='lensShape'
              placeholder='Lens Shape'
              value={this.props.jobInfo.lensShape || ""}
              onChange={({ target }) => {
                this.props.setRedux(target.name, target.value);
              }}
            />
            <button className="ui inverted icon button" type="button" onClick={this.props.fnShapeOpen}>
              <i className="eye icon"></i>
            </button>
          </div>
        </div>
        <div className='two wide field'>
          <label className='ui header'>Eye Size</label>
          <input
            type='text'
            name='lensEyesize'
            placeholder='Eye Size'
            value={this.props.jobInfo.lensEyesize || ""}
            onChange={({ target }) => {
              this.props.setRedux(target.name, target.value);
            }}
          />
        </div>
        <div className='two wide field'>
          <label className='ui header'>DBL</label>
          <input
            type='text'
            name='dblValue'
            placeholder='DBL'
            value={this.props.jobInfo.dblValue || ""}
            onChange={({ target }) => {
              this.props.setRedux(target.name, target.value);
            }}
          />
        </div>        
        <div className='three wide field'>
          <label className='ui header'>Tint Options</label>
          <Dropdown
            placeholder='Tint Options'
            options={this.state.lensTintList}
            value={this.state.tintValue}
            selection
            fluid
            disabled={this.state.tintDisabled}
            name='lensTint'
            onChange={async (e, { value, name }) => {
              await this.props.handleOnChange("lensTintDesc", "");
              await this.setState({ tintValue: value });
              await onChange(e, value, name);
              await this.props.coatingUpdate();
              await this.props.amountUpdate();
            }}
          />
        </div>
        <div className='seven wide field'>
          <label className='ui header'>Tint Description</label>
          <input
            type='text'
            name='lensTintDesc'
            placeholder='ex: pink color 50%'
            value={this.props.jobInfo.lensTintDesc}
            onChange={({ target }) => {
              this.props.handleOnChange(target.name, target.value);
            }}
            disabled={this.state.tintDisabled}
          />
          {(this.props.jobInfo.lensTint !== "NOTINT" &&
            this.props.jobInfo.lensTint !== "" &&
            this.props.jobInfo.lensTintDesc === "")
            && (<Label color="red" pointing>Please describe about tint</Label>)}
        </div>
      </div>)
  }

  regularLensShapeBlock = val => {
    const onChange = (e, val, name) => {
      this.props.handleOnChange(name, val);
    };

    return (
      <div className='fields'>
        <div className='four wide field'>
          <label className='ui header'>Tint Options</label>
          <Dropdown
            placeholder='Tint Options'
            options={this.state.lensTintList}
            value={this.state.tintValue}
            selection
            fluid
            disabled={this.state.tintDisabled}
            name='lensTint'
            onChange={async (e, { value, name }) => {
              await this.props.handleOnChange("lensTintDesc", "");
              await this.setState({ tintValue: value });
              await onChange(e, value, name);
              await this.props.coatingUpdate();
              await this.props.amountUpdate();
            }}
          />
        </div>
        <div className='twelve wide field'>
          <label className='ui header'>Tint Description</label>
          <input
            type='text'
            name='lensTintDesc'
            placeholder='ex: pink color 50%'
            value={this.props.jobInfo.lensTintDesc}
            onChange={({ target }) => {
              this.props.handleOnChange(target.name, target.value);
            }}
            disabled={this.state.tintDisabled}
          />
          {(this.props.jobInfo.lensTint !== "NOTINT" &&
            this.props.jobInfo.lensTint !== "" &&
            this.props.jobInfo.lensTintDesc === "")
            && (<Label color="red" pointing>Please describe about tint</Label>)}
        </div>
      </div>)
  }

  componentDidMount() {
    this._isMounted = true;
    this.mountLens(this.props.lsType, this.props.lsStyle);
  }

  componentDidUpdate() {
    const { validate } = this.props;

    if (this.state.validate !== validate) {
      //reset error field
      this.setState({
        err_lensColor: false,
        err_lensMaterial: false,
        err_lensStyle: false
      });
      //set error field according to submit button
      validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const onChange = (e, val, name) => {
      this.props.handleOnChange(name, val);
    };

    const onLensChange = async (e, val, name, text) => {

      this.setState({ [name]: val });
      await this.props.handleOnChange(name, val);
      await this.props.handleOnChange("lensStyleText", text);

      const lensKind = this.state.lenslist.find(e => e.value === this.props.jobInfo.lensStyle);
      await this.props.handleOnChange('lensKind', lensKind.lenskind);
      await this.props.handleOnChange('lensCoatingAval', lensKind.coatings);

      await this.mountLensMaterial(this.props.jobInfo.lensStyle);
      await this.mountLensColor(
        this.props.jobInfo.lensStyle,
        this.props.jobInfo.lensMaterial
      );
      await this.props.coatingUpdate();
      await this.props.amountUpdate(); //Order Amount update

      const lo = this.state.lenslist.find(
        e => e.value === this.props.jobInfo.lensStyle
      );
      this.props.handleOnChange("lensOption", lo.lensoption);
      this.props.customerInfoUpdate(lo.lensoption === 0 ? false : true);
      this.props.handleOnChange("powermax", lo.powermax);
      this.props.handleOnChange("powermin", lo.powermin);
      this.props.handleOnChange("cylmax", lo.cylmax);
      this.props.handleOnChange("addmax", lo.addmax);
      this.props.handleOnChange("lensType", lo.ltype);
      this.props.handleOnChange("lensDigital", lo.isdigital);
    };

    const onMaterialChange = async (val, name, text) => {
      await this.props.handleOnChange(name, val);
      await this.props.handleOnChange("lensMaterialText", text);
      await this.mountLensColor(
        this.props.jobInfo.lensStyle,
        this.props.jobInfo.lensMaterial
      );
      await this.props.coatingUpdate();
      await this.props.amountUpdate(); //Order Amount update
      //control tint options when material is Z
      if (val === "Z") {
        await this.setState({ tintDisabled: true, tintValue: "NOTINT" });
        await this.props.setRedux("lensTint", "NOTINT");
        await this.props.setRedux("lensTintDesc", "");
      } else {
        this.setState({ tintDisabled: false });
      }
      //console.log(this.props.disableColorGroove);

      if (this.props.disableColorGroove) {
        val === "C"
          ? this.props.disableColorGroove(true)
          : this.props.disableColorGroove(false);
      }
    };

    const acctType = this.props.jobInfo.acctNum && this.props.jobInfo.acctNum.substring(0, 1);

    const renderTint =
      (this.props.jobInfo.jobType === "POF" && acctType === "R") ? (
        this.retailLensShapeBlock()
      ) : (
          this.regularLensShapeBlock()
        );

    const renderList = (
      <div>
        <form className='ui form'>
          <div className='fields'>
            <div className='two wide field'>
              <label className='ui header'>Lens Type</label>
              <Dropdown
                icon='filter'
                floating
                labeled
                className='icon ui'
                button
                options={this.state.lensType}
                placeholder='Type'
                name='lensType'
                fluid
                selection
                value={this.props.jobInfo.lsType}
                onChange={async (e, { value, name }) => {
                  await this.props.setRedux("lensStyle", "");
                  await onChange(e, value, "lsType");
                  await this.mountLens();
                }}
              />
            </div>
            <div className='three wide field'>
              <label className='ui header'>Lens Style</label>
              <Dropdown
                icon='filter'
                floating
                labeled
                className='icon'
                button
                options={this.state.lensKind}
                placeholder='Kind'
                name='lensKind'
                fluid
                selection
                value={this.props.jobInfo.lsStyle}
                onChange={async (e, { value, name }) => {
                  await this.props.setRedux("lensStyle", "");
                  await onChange(e, value, "lsStyle");
                  await this.mountLens();
                }}
              />
            </div>
            <div className='six wide field'>
              <label className='ui header'>
                Lens Select - Type lens name to search
              </label>
              <Dropdown
                placeholder='Type Lens name to Search'
                search
                selection
                fluid
                options={this.state.lenslist}
                name='lensStyle'
                value={this.props.jobInfo.lensStyle}
                selectOnNavigation={false}
                onChange={async (e, { value, name, options }) => {
                  let text = options.find(e => e.value === value).text;
                  await onLensChange(e, value, name, text);
                  (await this.props.lensStyle) !== "" &&
                    this.setState({ err_lensStyle: false });
                }}
                error={this.state.err_lensStyle}
              />
            </div>

            <div className='three wide field'>
              <label className='ui header'>Material Select</label>
              <Dropdown
                placeholder='Material'
                selection
                fluid
                options={this.state.lensmaterial}
                name='lensMaterial'
                //selectOnNavigation={false}
                value={this.props.jobInfo.lensMaterial}
                onChange={async (e, { value, name, options }) => {
                  const text = options.find(e => e.value === value).text;
                  await onMaterialChange(value, name, text);
                  (await this.props.lensMaterial) !== "" &&
                    this.setState({ err_lensMaterial: false });
                }}
                error={this.state.err_lensMaterial}
              />
            </div>

            <div className='two wide field'>
              <label className='ui header'>Lens Color</label>
              <Dropdown
                placeholder='Color'
                selection
                fluid
                options={this.state.lensColorList}
                value={this.props.jobInfo.lensColor}
                name='lensColor'
                selectOnNavigation={true}
                onChange={async (e, { value, name }) => {
                  await onChange(e, value, name);
                  (await this.props.lensColor) !== "" &&
                    this.setState({ err_lensColor: false });
                }}
                error={this.state.err_lensColor}
              />
            </div>
          </div>

          {renderTint}

          <div className='fields'>
            <div className='sixteen wide field'>
              <div className='ui info icon message'>
                <i className='mini info circle icon'></i>
                <div className='content'>
                  Tinting : Polycarbonate, 1.60 & 1.67 High Index lenses accepts
                  tint upto roughly #2 density (~40%). For any tints darker than
                  #2 density, please select Trivex or CR39 lenses.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );

    return <div>{renderList}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.job, ownProps };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOnChange: (stateName, val) =>
      dispatch(actions.setDropDown(stateName, val)),
    setRedux: (name, value) => dispatch(setRedux(name, value)),
    setReduxOrder: (name, value) => dispatch(setReduxOrder(name, value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LensDropDown);
