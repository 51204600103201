import React from "react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";
import { Label } from "semantic-ui-react";
import _ from "lodash";

class CustomInfo extends React.Component {

  checkValue = (field, maxVal, minVal) => {
    const job = this.props;
    let tempVal = 0;
    let message = ""

    switch (field) {
      case "panto":
        message = "Pantos";
        break;
      case "wrap":
        message = "Wrap";
        break;
      case "vertexOD":
        message = "OD Vertex";
        break;
      case "vertexOS":
        message = "OS Vertex";
        break;
      default:
    }


    if (_.isNull(job[field])) return null;
    if (job[field] === "") return null;
    tempVal = _.toNumber(job[field]);

    if (tempVal > maxVal || tempVal < minVal) {
      return <Label color="red" pointing>
        {message + " must be between " + minVal + " and " + maxVal}
      </Label>
    }

  }

  renderContent = () => {
    return (
      <div className="field">
        <label className="ui header">Patient Behavior Information</label>
        <table className="ui table fluid">
          <thead>
            <tr>
              <th>Panto</th>
              <th>Wrap</th>
              <th>Vertex OD</th>
              <th>Vertex OS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ui input">
                  <input
                    placeholder="Panto"
                    name="panto"
                    value={this.props.panto}
                    onChange={e =>
                      this.props.setRedux(e.target.name, e.target.value)
                    }
                  />
                </div>
                {this.checkValue("panto", 12, 0)}
              </td>
              <td>
                <div className="ui input">
                  <input
                    placeholder="Wrap"
                    name="wrap"
                    value={this.props.wrap}
                    onChange={e =>
                      this.props.setRedux(e.target.name, e.target.value)
                    }
                  />
                </div>
                {this.checkValue("wrap", 12, 0)}
              </td>
              <td>
                <div className="ui input">
                  <input
                    placeholder="Vertex OD"
                    name="vertexOD"
                    value={this.props.vertexOD}
                    onChange={e =>
                      this.props.setRedux(e.target.name, e.target.value)
                    }
                  />
                </div>
                {this.checkValue("vertexOD", 16, 5)}
              </td>
              <td>
                <div className="ui input">
                  <input
                    placeholder="Vertex OS"
                    name="vertexOS"
                    value={this.props.vertexOS}
                    onChange={e =>
                      this.props.setRedux(e.target.name, e.target.value)
                    }
                  />
                </div>
                {this.checkValue("vertexOS", 16, 5)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    //console.log("CustomerInfo :" + this.props.customerInfo);
    return <div>{this.props.customerInfo && this.renderContent()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.job.jobInfo, ownProps };
};

export default connect(
  mapStateToProps,
  { setRedux }
)(CustomInfo);
