import React from "react";
import { Button, Modal } from "semantic-ui-react";
import italeeApi from "../api/italeeApi";
import { connect } from "react-redux";

class UserIdManage extends React.Component {
  _isMounted  = false;
  state = {
    open: false,
    data: [
      {
        id: 1,
        userid: "",
        password: "",
        email: ""
      }
    ],
    addData: {
      userid: "",
      password: "",
      macct: this.props.acctNum,
      email: ""
    }
  };
  closeModal = this.props.closeModal;
  showModal = this.props.showModal;

  resetAddData = () => {
    this.setState({
      addData: {
        userid: "",
        password: "",
        macct: this.props.acctNum,
        email: ""
      }
    });
  };

  loadUserId = async () => {
    const result = await italeeApi.get(`/userid/${this.props.acctNum}`);
    this.setState({ data: result.data });
  };

  removeUserId = async removeId => {
    await italeeApi.delete(`/userid/${removeId}`);
    this.loadUserId();
  };

  addUserId = async addData => {
    await italeeApi.post(`/userid/`, addData);
    this.loadUserId();
    this.resetAddData();
  };

  componentDidUpdate() {
    this.props.open !== this.state.open &&
      this.setState({ open: this.props.open });
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUserId();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const temp = this.state.data.filter(e=>e.id !== 0 );
    const result = temp.map(e => {
      return (
        <tr key={e.id}>
          <td>{e.userid}</td>
          <td>{e.password}</td>
          <td>{e.email}</td>
          <td>
            <div
              className="ui small orange labeled icon button"
              onClick={() => {
                this.removeUserId(e.id);
              }}
            >
              <i className="user times icon" />
              Remove
            </div>
          </td>
        </tr>
      );
    });

    const addUser = (
      <tr key="99">
        <td>
          <div className="ui input fluid">
            <input
              type="text"
              className="ui input"
              placeholder="User Id"
              value={this.state.addData.userid}
              onChange={e =>
                this.setState({
                  addData: { ...this.state.addData, userid: e.target.value }
                })
              }
            />
          </div>
        </td>
        <td>
          <div className="ui input fluid">
            <input
              type="text"
              className="ui input"
              placeholder="Password"
              value={this.state.addData.password}
              onChange={e =>
                this.setState({
                  addData: { ...this.state.addData, password: e.target.value }
                })
              }
            />
          </div>
        </td>
        <td>
          <div className="ui input fluid">
            <input
              type="text"
              placeholder="eMail address"
              value={this.state.addData.email}
              onChange={e =>
                this.setState({
                  ...this.state.addData,
                  addData: { ...this.state.addData, email: e.target.value }
                })
              }
            />
          </div>
        </td>
        <td>
          <div
            className="ui small blue labeled icon button"
            onClick={() => this.addUserId(this.state.addData)}
          >
            <i className="user plus icon" />
            Add User
          </div>
        </td>
      </tr>
    );

    const { open } = this.state;

    return (
      <div>
        <Modal open={open} size="small" onClose={this.closeModal}>
          <Modal.Header>
            <h3 className="ui header">
              <i className="users icon" />
              <div className="content">Manage User Id</div>
            </h3>
          </Modal.Header>
          <Modal.Content>
            <div>
              <table className="ui selectable table ">
                <thead>
                  <tr>
                    <th className="four wide">User Id</th>
                    <th className="four wide">Password</th>
                    <th className="four wide">eMail address</th>
                    <th className="four wide" />
                  </tr>
                </thead>
                <tbody>
                  {result}
                  {addUser}
                </tbody>
              </table>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal}>Exit</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(
  mapStateToProps,
  null
)(UserIdManage);
