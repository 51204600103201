import React from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import italeeApi from "../../api/italeeApi";
import * as actions from "../../actions";
import TextField from "@material-ui/core/TextField";
//import { setRedux } from "./../../actions/index";

class PatientName extends React.Component {
  state = {
    stateList: [],
    err_patientName: false,
    err_patientLastName: false,
    validate: this.props.validate
  };

  componentDidUpdate() {
    const { validate } = this.props;

    if (this.state.validate !== validate) {
      //reset error field
      this.setState({
        err_patientName: false,
        err_patientLastName: false
      });
      //set error field according to submit button
      validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate });
    }
    //console.log(this.props.validateField);
  }

  renderAcctAddr = async (value, name, acctList) => {
    await this.props.handleDropdown(name, value);
    //run prop funciton StatementRefresh if parent component sent
    this.props.StatementRefresh && (await this.props.StatementRefresh());
    const result = acctList.find(i => i.value === value);
    if (result) {
      this.props.handleDropdown("acctAddress", result.addr);
      this.props.handleDropdown("acctCity", result.city);
      this.props.handleDropdown("acctState", result.sta);
      this.props.handleDropdown("acctZip", result.zip);
      this.props.handleDropdown("acctName", result.name);
    }
  };

  patienNameField = () => {
    const validate = this.props.jobInfo;
    return (
      <div className="fields">
        <div className="eight wide field">
          <label>Patient First Name</label>
          <TextField
            id="patientName"
            type="text"
            value={validate.patientName || ""}
            placeholder="Patient First Name"
            onChange={e => {
              this.props.setRedux("patientName", e.target.value);
            }}
            onBlur={() => {
              validate.patientName !== "" &&
                this.setState({ err_patientName: false });
            }}
            fullWidth
            variant="outlined"
            error={this.state.err_patientName}
          />
        </div>
        <div className="eight wide field">
          <label>Last Name</label>
          <TextField
            type="text"
            name="patientLastName"
            placeholder="Patient Last Name"
            onChange={async e => {
              await this.props.setRedux(e.target.name, e.target.value);
              (await validate.patientLastName) !== "" &&
                this.setState({ err_patientLastName: false });
            }}
            fullWidth
            variant="outlined"
            error={this.state.err_patientLastName}
            value={validate.patientLastName || ""}
          />
        </div>
      </div>
    );
  };

  componentDidMount() {
    if (this.props.jobInfo.acctCif) {
      italeeApi
        .get(`/LoginAuth/${this.props.jobInfo.acctCif}`)
        .then(response => {
          //console.log(response.data);
          this.setState({ stateList: response.data });
        })
        .catch(() => {
          console.log("Error...");
        });

      const acctItem = { key: 0, text: "All Accounts", value: "All" };

      if (this.props.AllValueInc) {
        this.setState({ stateList: [acctItem, ...this.state.stateList] });
      }
    }
  }

  render() {
    /*

    this.setState({ stateList: resultApi });
    let acctList = [
      {
        key: 1,
        text: "RIOCV",
        value: "RIOCV",
        name: "Italee Optics",
        addr: "2641 W Olympic Blvd",
        city: "Los Angeles",
        sta: "CA",
        zip: "90006"
      },
      { key: 2, text: "L9050", value: "L9050" },
      { key: 3, text: "1050", value: "1050" },
      { key: 4, text: "7022", value: "7022" },
      { key: 5, text: "2050", value: "2050" }
    ];
  */

    return (
      <div>
        <div className="ui form">
          <div className="field">
            <div className="field">
              <label>Account Number</label>
              <Dropdown
                options={this.state.stateList}
                placeholder="Select Account Number"
                selection
                fluid
                defaultValue={this.props.jobInfo.acctNum}
                name="acctNum"
                onChange={async (e, { value, name }) => {
                  this.renderAcctAddr(value, name, this.state.stateList);
                }}
              />
            </div>

            {this.props.PatientField && this.patienNameField()}

            <div className="ui segment">
              <div className="field">
                <h3 className="item ui header">
                  {this.props.jobInfo.acctName}
                </h3>
                <h4 className="item ui header">
                  {this.props.jobInfo.acctAddress}
                </h4>
              </div>

              <div className="fields">
                <div className="eight wide field">
                  <h4 className="ui header">{this.props.jobInfo.acctCity}</h4>
                </div>
                <div className="four wide field">
                  <h4 className="ui header">{this.props.jobInfo.acctState}</h4>
                </div>
                <div className="four wide field">
                  <h4 className="ui header">{this.props.jobInfo.acctZip}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.job, ownProps };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOnChange: val => dispatch(actions.setRx(val)),
    handleDropdown: (stateName, val) =>
      dispatch(actions.setDropDown(stateName, val)),
    setRedux: (name, val) => dispatch(actions.setRedux(name, val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientName);
