import React from "react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";

class SpecialMemo extends React.Component {
  render() {
    return (
      <div className="ui form">
        <div className="field">
          <label className="ui header">Special Memo & Instruction</label>
          <input
            name="specialMemo"
            placeholder="Enter Special Memo and Instructions"
            onChange={({ target }) => {
              this.props.setRedux(target.name, target.value);
            }}
            value={this.props.specialMemo}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.job.jobInfo, ownProps };
};

export default connect(
  mapStateToProps,
  { setRedux }
)(SpecialMemo);
