export const RXINFO_UPDATE = "RXINFO_UPDATE";
export const DROPDOWN_UPDATE = "DROPDOWN_UPDATE";
export const TEXT_UPDATE = "TEXT_UPDATE";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_ACCTINFO = "UPDATE_ACCTINFO";
export const SHIP_DROPDOWN_UPDATE = "SHIP_DROPDOWN_UPDATE";
export const SHIP_TEXT_UPDATE = "SHIP_TEXT_UPDATE";
export const SET_REDUX = "SET_REDUX";
export const RESET = "RESET";
export const SET_REDUX_SHIP = "SET_REDUX_SHIP";
export const SET_REDUX_ORDER = "SET_REDUX_ORDER";
