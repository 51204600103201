import React from "react";
import NumberFormat from "react-number-format";

class InvRxInfo extends React.Component {
  state = { infos: {} };

  componentDidUpdate() {
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });
  }

  formatNumber = (val, scale, prefix = "") => {
    const valPrefix = val >= 0 ? prefix : "";
    return (
      <NumberFormat
        value={val}
        displayType={"text"}
        decimalScale={scale}
        fixedDecimalScale={true}
        prefix={valPrefix}
      />
    );
  };

  render() {
    //const val = this.state.infos;
    const val = this.props.datas;
    //console.log(val);

    const lensTypeDesc = param => {
      switch (param) {
        case "pr":
          return "PROGRESSIVE"
        case "mf":
          return "MULTI FOCAL"
        case "sv":
          return "SINGLE VISION"
        default:
          return param

      }
    }

    let osLensType = lensTypeDesc(val.osLensName);
    let odLensType = lensTypeDesc(val.odLensName);

    return (
      <>
        <div className="ui basic segment">
          <h4>Rx Information</h4>
          <table className="ui compact definition table">
            <thead>
              <tr>
                <th></th>
                <th>SPH</th>
                <th>CYL</th>
                <th>AXIS</th>
                <th>PRISM</th>
                <th>BASE</th>
                <th>TYPE</th>
                <th>COLOR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>OD</td>
                <td>{this.formatNumber(val.odSph, 2, "+")}</td>
                <td>{this.formatNumber(val.odCyl, 2, "+")}</td>
                <td>{val.odAxis}</td>
                <td>
                  {val.odPrismH} {val.odPrismV}
                </td>
                <td>{val.odBase}</td>
                <td>{odLensType}</td>
                <td>{val.odLensColor}</td>
              </tr>
              <tr>
                <td>OS</td>
                <td>{this.formatNumber(val.osSph, 2, "+")}</td>
                <td>{this.formatNumber(val.osCyl, 2, "+")}</td>
                <td>{val.osAxis}</td>
                <td>
                  {val.osPrismH} {val.osPrismV}
                </td>
                <td>{val.osBase}</td>
                <td>{osLensType}</td>
                <td>{val.osLensColor}</td>
              </tr>
            </tbody>
          </table>
          <table className="ui compact definition table">
            <thead>
              <tr>
                <th></th>
                <th>ADD</th>
                <th>SGHT/OC</th>
                <th>PD</th>
                <th>NPD</th>
                <th>THICKNESS</th>
                <th>MATERIAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>OD</td>
                <td>{this.formatNumber(val.odAdd, 2)}</td>
                <td>{val.odSegHt}</td>
                <td>{this.formatNumber(val.odPD, 1)}</td>
                <td>{this.formatNumber(val.odNPD, 1)}</td>
                <td>{this.formatNumber(val.odThk, 1)}</td>
                <td>{val.odLensMaterial}</td>
              </tr>
              <tr>
                <td>OS</td>
                <td>{this.formatNumber(val.osAdd, 2)}</td>
                <td>{val.osSegHt}</td>
                <td>{this.formatNumber(val.osPD, 1)}</td>
                <td>{this.formatNumber(val.osNPD, 1)}</td>
                <td>{this.formatNumber(val.osThk, 1)}</td>
                <td>{val.osLensMaterial}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default InvRxInfo;
