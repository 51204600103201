import React from "react";
import _ from "lodash";

class InvJobInfo extends React.Component {
  state = { infos: {} };

  componentDidUpdate() {    
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });      
  }

  renderUncut = () => {
    const val = this.props.datas;
    return (
      <div className="ui basic segment">
        <table className="ui compact definition table">
          <thead>
            <tr>
              <th className="four wide"></th>
              <th className="two wide">A</th>
              <th className="two wide">DBL</th>
              <th className="two wide">ED</th>
              <th className="two wide">B</th>
              <th className="two wide">SHAPE</th>
              <th className="two wide">EYESIZE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lens Dimention</td>
              <td>{val.valA}</td>
              <td>{val.valDBL}</td>              
              <td>{val.valED}</td>
              <td>{val.valB}</td>              
              <td>{val.shapeName}</td>
              <td>{val.eyeSize}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderPOF = () => {
    //const val = this.state.infos;
    const val = this.props.datas;
    const tempStr = _.replace(_.replace(val.instruction,"[POF:]","|"),"[ENGRAVING:]","|") ;
    const message = _.split(tempStr,"|");

    return (
      <div>
        {this.renderUncut()}
        <div className="ui basic segment">
          <table className="ui celled compact table">
            <thead>
              <tr>
                <th>Patient Own Frame Information</th>
                <th>Laser Engraving Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{message[1]}</td>
                <td>{message[2]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    const uncut = this.props.uncut;
    const toRender = uncut ? this.renderUncut() : this.renderPOF();    
    return toRender;
  }
}

export default InvJobInfo;
