import React from "react";
import Header from "./../Header";
import Footer from "./../../Footer";
import PatientName from "../subcomponent/PatientName";
import DataTable from "../subcomponent/DataTable";
import { connect } from "react-redux";
import italeeApi from "../../api/italeeApi";
import _ from "lodash";
import { Pagination } from "semantic-ui-react";
import Modalbox from "../Modal";
import ViewOrder from "./ViewOrder";

class Jobstatus extends React.Component {
  _isMounted = false;

  state = {
    currPage: 1,
    totalPage: 1,
    pageSize: 25,
    tableBody: [],
    modalOpen: true,
    open: false,
    ponum: null
  };

  loadData = async () => {
    this._isMounted && this.setState({ modalOpen: true });
    const acctNum = this.props.acctNum;
    const startRec = (this.state.currPage - 1) * this.state.pageSize + 1;
    const endRec = this.state.currPage * this.state.pageSize;
    const response = await italeeApi.get(
      `JobStatus/GetJobStatus/${acctNum}/${startRec}/${endRec}`
    );
    const totalRec = response.data[0].body[6].data;
    const totalPage = Math.ceil(totalRec / this.state.pageSize);
    this._isMounted && this.setState({ totalPage });
    const renderData = _.drop(response.data);
    this._isMounted && (await this.setState({ tableBody: renderData }));
    this._isMounted && this.setState({ modalOpen: false });
  };

  onAcctChange = async () => {
    await this.setState({ currPage: 1 });
    await this.loadData();
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showViewOrder = val => {
    this.setState({ open: true, ponum: val.value });
  };

  closeViewOrder = () => {
    this.setState({ open: false });
  };

  render() {
    const tableTitles = [
      {
        key: 1,
        title: [
          { key: 1, data: "Acct#" },
          { key: 2, data: "Order Date" },
          { key: 3, data: "PO #" },
          { key: 4, data: "Job Name" },
          { key: 5, data: "Job Type" },
          { key: 6, data: "Status" },
          { key: 7, data: "Station" }
        ]
      }
    ];

    // const tableBody = [
    //   {
    //     key: 1,
    //     body: [
    //       { key: 1, data: "Date1" },
    //       { key: 2, data: "Date2" },
    //       { key: 3, data: "Date3" },
    //       { key: 4, data: "Date4" },
    //       { key: 5, data: "Date5" },
    //       { key: 6, data: "Date6" }
    //     ]
    //   }
    // ];

    const tableFoot = [
      {
        key: 1,
        foot: [
          { key: 1, data: "" },
          { key: 2, data: "" },
          { key: 3, data: "" },
          { key: 4, data: "" },
          { key: 5, data: "" },
          { key: 6, data: "" },
          { key: 7, data: "" }
        ]
      }
    ];

    return (
      <div>
        <Header currMenu="JOBSTATUS" />
        <div className="ui main container">
          <div className="ui container">
            <h3 className="ui header">
              <i className="sync icon" />
              <div className="content">Job Status</div>
            </h3>

            <div className="ui segment">
              <div className="ui form">
                <div className="two fields">
                  <div className="eight wide field">
                    <PatientName StatementRefresh={this.onAcctChange} />
                  </div>
                  <div className="eight wide field" />
                </div>
              </div>

              <div className="ui header small">Job List</div>
              <DataTable
                tableTitle={tableTitles}
                tableBody={this.state.tableBody}
                tableFoot={tableFoot}
                tableColor={"purple"}
                showInvoice={this.showViewOrder}
                Sortable
              />

              <Pagination
                defaultActivePage={this.state.currPage}
                totalPages={this.state.totalPage}
                onPageChange={async (e, data) => {
                  await this.setState({ currPage: data.activePage });
                  await this.loadData();
                }}
              />
            </div>
          </div>
        </div>
        <Footer />
        <Modalbox modalOpen={this.state.modalOpen} />
        <ViewOrder
          open={this.state.open}
          closeInvoice={this.closeViewOrder}
          ponum={this.state.ponum}
          title={"Order Details"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(
  mapStateToProps,
  null
)(Jobstatus);
