import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import EdgeType from "./EdgeType";

class PofInformation extends React.Component {

  
  renderEdgeType = acctType => {
    return (this.props.jobType === "POF" && acctType === "R") ?
      <EdgeType /> : <div></div>
  }

  render() {

    const acctType = this.props.acctNum && this.props.acctNum.substring(0, 1);


    return (
      <div className='fields'>
        <div className="ten wide field">
          <label className="ui header">Patient Own Frame Information</label>
          <table className='ui table fluid'>
            <thead>
              <tr>
                <th>
                  Brief desciption of Patient's Frame including Frame Model and
                  Color
              </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className='ui input'>
                    <input
                      placeholder='Frame Information'
                      name='pofInformation'
                      onChange={({ target }) =>
                        this.props.setRedux(target.name, target.value)
                      }
                      value={this.props.pofInformation}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="six wide field">
          {this.renderEdgeType(acctType)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSetRx: val => dispatch(actions.setRx(val)),
    setRedux: (name, val) => dispatch(actions.setRedux(name, val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PofInformation);
