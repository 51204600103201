import * as types from "../actions/actionTypes";

const initStateShip = {
  shipInfo: {
    shipNum: "",
    shipName: "",
    shipAddress: "",
    shipCity: "",
    shipZip: "",
    shipState: ""
  }
};

export default (state = initStateShip, action) => {
  switch (action.type) {
    case types.SHIP_TEXT_UPDATE:
      return {
        ...state,
        shipInfo: { ...state.shipInfo, [action.load.name]: action.load.value }
      };
    case types.SHIP_DROPDOWN_UPDATE:
      return {
        ...state,
        shipInfo: {
          ...state.shipInfo,
          [action.load.stateName]: action.load.val
        }
      };

    case types.SET_REDUX_SHIP:
      return {
        ...state,
        shipInfo: {
          ...state.shipInfo,
          [action.name]: action.load
        }
      };

    default:
      return state;
  }
};
