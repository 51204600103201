import React from "react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { Label } from "semantic-ui-react";

class LensDimention extends React.Component {
  state = {
    err_aValue: false,
    err_bValue: false,
    err_edValue: false,
    err_dblValue: false,
    validate: this.props.validate
  };

  componentDidUpdate() {
    const { validate } = this.props;

    if (this.state.validate !== validate) {
      //reset error field
      this.setState({
        err_aValue: false,
        err_bValue: false,
        err_edValue: false,
        err_dblValue: false
      });
      //set error field according to submit button
      validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate });
    }
  }

  checkA = () => {
    const job = this.props;
    if (_.isNull(job.edValue)) return null;
    if (job.edValue === "") return null;

    return _.toNumber(job.aValue) > _.toNumber(job.edValue) ? (
      <Label color="red" pointing>
        {"A can't be bigger than ED"}
      </Label>
    ) : (
        ""
      );
  };

  checkB = () => {
    const job = this.props;
    if (_.isNull(job.edValue)) return null;
    if (job.edValue === "") return null;

    return _.toNumber(job.bValue) > _.toNumber(job.edValue) ? (
      <Label color="red" pointing>
        {"B can't be bigger than ED"}
      </Label>
    ) : (
        ""
      );
  };

  checkEDDBL = field => {
    const job = this.props;

    if (field === "ED") {
      if (_.isNull(job.edValue)) return null;
      if (job.edValue === "") return null;
      if (_.toNumber(job.edValue) > 90) {
        return <Label color="red" pointing>
          {"ED can't exceed 90"}
        </Label>
      }
    }
    else {
      if (_.isNull(job.dblValue)) return null;
      if (job.edValue === "") return null;
      if (_.toNumber(job.dblValue) > 40) {
        return <Label color="red" pointing>
          {"DBL can't exceed 40"}
        </Label>
      }
    }

  }

  render() {
    return (
      <div className="field">
        <label className="ui header">Measurment</label>
        <table className="ui table fluid">
          <thead>
            <tr>
              <th className="four wide">A</th>
              <th className="four wide">DBL</th>
              <th className="four wide">ED</th>
              <th className="four wide">B</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ui input">
                  <TextField
                    placeholder="A"
                    name="aValue"
                    value={this.props.aValue}
                    onChange={async e => {
                      await this.props.setRedux(e.target.name, e.target.value);
                      (await this.props.aValue) !== "" &&
                        this.setState({ err_aValue: false });
                    }}
                    error={this.state.err_aValue}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {this.checkA()}
              </td>
              <td>
                <div className="ui input">
                  <TextField
                    placeholder="DBL"
                    name="dblValue"
                    value={this.props.dblValue}
                    onChange={async e => {
                      await this.props.setRedux(e.target.name, e.target.value);
                      (await this.props.dblValue) !== "" &&
                        this.setState({ err_dblValue: false });
                    }}
                    error={this.state.err_dblValue}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {this.checkEDDBL("DBL")}
              </td>                            
              <td>
                <div className="ui input">
                  <TextField
                    placeholder="ED"
                    name="edValue"
                    value={this.props.edValue}
                    onChange={async e => {
                      await this.props.setRedux(e.target.name, e.target.value);
                      (await this.props.edValue) !== "" &&
                        this.setState({ err_edValue: false });
                    }}
                    error={this.state.err_edValue}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {this.checkEDDBL("ED")}
              </td>              
              <td>
                <div className="ui input">
                  <TextField
                    placeholder="B"
                    value={this.props.bValue}
                    name="bValue"
                    onChange={async e => {
                      await this.props.setRedux(e.target.name, e.target.value);
                      (await this.props.bValue) !== "" &&
                        this.setState({ err_bValue: false });
                    }}
                    error={this.state.err_bValue}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {this.checkB()}
              </td>              
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(mapStateToProps, { setRedux })(LensDimention);
