import React from "react";
import { connect } from "react-redux";
import italeeApi from "../../api/italeeApi";
import { Button, Form, Grid, Image, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as actions from "../../actions";
import history from "../../history";
import pkg from "../../../package.json";

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { UserID: "", Password: "", errorMsg: "", isLoading: true };
  }

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAccountInformation = async acctnum => {
    if (!this._isMounted) return;
    await italeeApi
      .get(`/LoginAuth/acctinfo?acctnum=${acctnum}`)
      .then(response => {
        //console.log(response.data[0]);
        this.props.handleUpdateAcctInfo(response.data[0]);
      })
      .catch(() => {
        console.log("Error getting account info.");
      });
  };

  onSubmit = async () => {
    this.setState({ isLoading: false }); //change to loading icon
    await italeeApi
      .get(`/LoginAuth?id=${this.state.UserID}&pw=${this.state.Password}`)
      .then(response => {
        this.props.handleOnSubmit(response.data[0]);
        //console.log(this.props.job.jobInfo.acctNum)
        this.getAccountInformation(this.props.job.jobInfo.acctNum);
        history.push("/home");
      })
      .catch(error => {
        console.log(error);
        this.setState({ errorMsg: "error", isLoading: true });
      });
  };

  errorMessage = () => {
    if (this.state.errorMsg !== "") {
      return (
        <Message negative>
          Login failed. Please check login information.
        </Message>
      );
    }
  };

  render() {
    return (
      <div className="login-form" style={{ backgroundColor: "#e4e4e4" }}>
        {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
        <style>
          {`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}
        </style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src="/italeeLogo.png" />
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Account Number or Sub ID"
                  value={this.state.UserID}
                  onChange={e => this.setState({ UserID: e.target.value })}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  value={this.state.Password}
                  onChange={e => this.setState({ Password: e.target.value })}
                />

                {this.errorMessage()}

                {this.state.isLoading ?
                  <Button
                    fluid
                    size="large"
                    className="ui button primary"
                    onClick={this.onSubmit}
                  >
                    Login
                </Button>
                  :
                  <Button
                    fluid
                    size="large"
                    className="ui loading  disable dbutton primary"
                  >Loading</Button>
                }
              </Segment>
            </Form>
            <Message>
              Need Offiline Italee Account number to
              <div className="ui label">
                <i className="edit icon" />
                <Link to="/register">Register</Link>
              </div>
            </Message>
            <div>
              <i className="react icon"></i>
              {pkg.version}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOnSubmit: val => dispatch(actions.getLogin(val)),
    handleUpdateAcctInfo: val => dispatch(actions.updateAccountInfo(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
