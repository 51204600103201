import React from "react";
import italeeApi from "../../api/italeeApi";

class InvShapeView extends React.Component {
    _isMounted = false;
    state = { shapeDataOD: "", shapeDataOS: "", shapeName: "", eyeSize: "", isLoading: true }


    componentDidUpdate = async () => {
        const { shapeName, eyeSize } = this.props;
        if (shapeName && shapeName !== this.state.shapeName && eyeSize !== this.state.eyeSize) {
            const response = await italeeApi.get(`lens/GetShapeData/${shapeName}/${eyeSize}`);
            this.setState({
                shapeDataOD: response.data[0].shapedataod,
                shapeDataOS: response.data[0].shapedataos,
                shapeName,  //: response.data[0].shapename,
                eyeSize, //: response.data[0].eyesize,
                realEyesize : response.data[0].eyesize,
                realShapeName : response.data[0].shapename,
                isLoading: false
            });
        }

    }

    render() {

        const style = {
            border: "0px solid",
            height: "300",
            width: "600",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

        const style2 = {
            border: "0px solid",
            height: "300",
            width: "600"

        }

        const style3 = {
            fontSize: "15px"
        }

        return (this.state.realEyesize !== 0 && !this.state.isLoading) ?
            (

                
                    <div style={style} >
                        <svg style={style2}>
                            <polyline points={this.state.shapeDataOD} stroke="black" fill="transparent" strokeWidth="2" strokeLinecap="round" />
                            <polyline points={this.state.shapeDataOS} stroke="black" fill="transparent" strokeWidth="2" strokeLinecap="round" />
                            <text x="0" y="30" fill="black" style={style3}>{this.state.realShapeName + " - " + this.state.realEyesize} </text>
                        </svg>
                    </div>
                

            ) : null
    }

}

export default InvShapeView