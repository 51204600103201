import React from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { setRedux } from "../../actions";


class EdgeType extends React.Component {
    state = {
        edgeType: [
            { key: 0, text: "", value: "" },
            { key: 1, text: "Metal Frame", value: "METAL" },
            { key: 2, text: "Zyle Frame", value: "ZYLE" },
            { key: 3, text: "Drill Mount", value: "DRILL" },
            { key: 4, text: "Groove Mount", value: "GROOVE" },
            { key: 5, text: "Safety", value: "" }
        ]
    }
    renderEdgeTypeOrLaserEngraving = () => {
        return (
            <div className='field'>
                <label className="ui header">Edge Type</label>
                <table className="ui table fluid">
                    <thead>
                        <tr>
                            <th>
                                Select Edge type
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr><td>
                        <Dropdown
                            selection
                            options={this.state.edgeType}
                            fluid
                            placeholder='Select Edge Type'
                            name='lensEdgeType'
                            value={this.props.lensEdgeType}
                            onChange={async (e, { value, name }) => {
                                await this.props.setRedux(name, value);
                                (await this.props.lensCoating) !== "" &&
                                    this.setState({ err_lensEdgeType: false });
                            }}
                            error={this.state.err_lensEdgeType}
                        />{" "}
                    </td></tr>
                    </tbody>
                </table>
            </div>
        );
    }



    render() {
        return (
            <div>
                {this.renderEdgeTypeOrLaserEngraving()}
            </div>
        );

    }
}

const mapStateToProps = state => {
    return { ...state.job.jobInfo }
}

export default connect(mapStateToProps, { setRedux })(EdgeType);
