import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";

class InvDetailCp extends React.Component {
  state = { infos: {}, details: [] };

  componentDidUpdate() {
    this.state.infos !== this.props.datas &&
      this.setState({ infos: this.props.datas });
    this.state.details !== this.props.details &&
      this.setState({ details: this.props.details });
  }

  formatMoney = val => {
    return (
      <NumberFormat
        value={val}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={"$"}
      />
    );
  };

  formatRate = val => {
    return (
      <NumberFormat
        value={val * 100}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={3}
        fixedDecimalScale={true}
        suffix={"%"}
      />
    );
  };

  shipPrice = (mode, val) => {
    return mode === "view" ?
      <>
        <tr>
          <td className="right aligned">
            <h4>ESTIMATED TOTAL</h4>
          </td>
          <td className="right aligned">
            <h4>{this.formatMoney(val.grandTotal)}</h4>
          </td>
        </tr>
      </>
      :
      <>
        <tr>
          <td className="right aligned">SHIP TOTAL</td>
          <td className="right aligned">
            {this.formatMoney(val.shipPrice)}
          </td>
        </tr>
        <tr>
          <td className="right aligned">
            <h4>TOTAL</h4>
          </td>
          <td className="right aligned">
            <h4>{this.formatMoney(val.grandTotal)}</h4>
          </td>
        </tr>
      </>
  }

  render() {
    //const val = this.state.infos;
    const val = this.props.datas;
    const temp = this.state.details;
    //const details = temp.filter(item => item.itemDesc !== 'SUBTOTAL' && item.itemDesc !== 'DISCRATE' && item.itemDesc !== 'DISCAMT');


    if (this.props.mode === "view") {
      const subt = temp.find(item => item.itemDesc === 'SUBTOTAL');
      const discRate = temp.find(item => item.itemDesc === 'DISCRATE');
      const discAmt = temp.find(item => item.itemDesc === 'DISCAMT');
      val.subTotal = subt ? subt.itemPrice : 0;
      val.discRate = discRate ? discRate.itemPrice : 0;
      val.discAmt = discAmt ? discAmt.itemPrice : 0;
      val.grandTotal = val.subTotal - val.discAmt;
    }

    const message = _.split(val.instruction, "|");
    // const sampleData = [
    //   {
    // "key": 1,
    // "itemDesc": "RIGHT LENS",
    // "itemPrice": 24.5,
    // "itemQty": 1,
    // "itemTaxable": "y"
    //   }
    // ];
    return (
      <div className="ui basic segment">
        <div className="sixteen wide field">
          <table className="ui celled compact table">
            <thead>
              <tr>
                <th style={{ width: "33%" }}>Lens Style</th>
                <th style={{ width: "33%" }}>Lens Material</th>
                <th style={{ width: "33%" }}>Lens Coating</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{val.lensStyle}&nbsp;</td>
                <td>{val.lensMaterial}&nbsp;</td>
                <td>{val.lensCoating}&nbsp;</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Tint</th>
                <th>Edge Type</th>
                <th>Edge Treatment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{val.tint + ' ' + val.tintOther}&nbsp;</td>
                <td>{val.lensEdgeType}&nbsp;</td>
                <td>{val.edgeTreatment}&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <table className="ui compact celled table">
            <tbody>
              <tr>
                <td className="tree wide active">Job Instruction</td>
                <td className="thirteen wide">{message[0]}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="ui very basic compact table">
          <tbody>
            <tr>
              <td className="eleven wide top aligned">
                <div className="ui message">
                  <div className="header">{val.specialMemo}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvDetailCp;
