import React from "react";
import { Modal, Button } from "semantic-ui-react";

class MessageBox extends React.Component {
  state = { open: true };

  componentDidUpdate = () => {
    this.props.options.open !== this.state.open &&
      this.setState({ open: this.props.options.open });
  };

  componentDidMount() {
    const {
      style,
      title,
      message,
      size,
      handleClose,
      success
    } = this.props.options;

    this.setState({ handleClose, style, title, message, size, success });
  }

  showButtons = () => {
    const { style, handleClose, success } = this.state;
    switch (style) {
      case "yes":
        return (
          <div>
            <Button
              content="Yes"
              className="ui button green"
              onClick={() => {
                handleClose();
                success !== null && success();
              }}
            />
          </div>
        );

      case "yesno":
        return (
          <div>
            <Button
              content="Yes"
              className="ui button green"
              onClick={() => {
                handleClose();
                success !== null && success();
              }}
            />
            <Button content="No" className="ui button orange" />
          </div>
        );
      case "yescancel":
        return (
          <div>
            <Button
              content="Yes"
              className="ui button blue"
              onClick={() => {
                handleClose();
                success !== null && success();
              }}
            />
            <Button content="Cancel" className="ui button red" />
          </div>
        );

      case "ok":
        return (
          <div>
            <Button
              content="OK"
              className="ui button blue"
              onClick={() => {
                handleClose();
                success !== null && success();
              }}
            />
          </div>
        );

      default:
    }
  };

  //handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  render() {
    const showButtons = this.showButtons();
    return (
      <Modal
        open={this.state.open}
        size={this.state.size}
        onClose={this.state.handleClose}
      >
        <Modal.Header>{this.state.title}</Modal.Header>
        <Modal.Content>{this.state.message}</Modal.Content>
        <Modal.Actions>{showButtons}</Modal.Actions>
      </Modal>
    );
  }
}

export default MessageBox;
