import React from "react";
import { connect } from "react-redux";
import { setRedux } from "./../../actions/index";
import { Table, Label } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import _ from "lodash"

class RxinfoOS extends React.Component {
  state = {
    err_os_sph: false,
    err_os_cyl: false,
    err_os_axis: false,
    err_os_add: false,
    err_os_pd: false,
    err_os_seg: false,
    err_os_oc: false,
    validate: this.props.validate,
    add_disabled: false,
    oc_disabled: false,
    seg_disabled: false,
    axis_disabled: true,
    resetCode: this.props.resetCode,
    lensType: ""
  };

  componentDidUpdate() {
    //error check
    if (this.state.validate !== this.props.validate) {
      //reset error field
      this.setState({
        err_os_sph: false,
        err_os_cyl: false,
        err_os_axis: false,
        err_os_add: false,
        err_os_pd: false,
        err_os_seg: false,
        err_os_oc: false
      });
      //set error field according to submit button
      this.props.validate.map(item => {
        this.setState({ ["err_" + item]: true });
        return null;
      });
      this.setState({ validate: this.props.validate });
    }

    //lens type check
    if (this.props.lensType !== this.state.lensType) {
      this.setState({
        add_disabled: false,
        oc_disabled: false,
        seg_disabled: false
      });

      if (this.props.lensType === "sv") {
        this.setState({ add_disabled: true, seg_disabled: true });
        this.props.setRedux("os_add", "");
        this.props.setRedux("os_seg", "");
      } else {
        this.setState({ oc_disabled: true });
        this.props.setRedux("os_oc", "");
      }
      this.setState({ lensType: this.props.lensType });
    }

    //reset
    this.state.resetCode !== this.props.resetCode &&
      this.setState({ resetCode: this.props.resetCode, axis_disabled: true });
  }

  copyOdToOs = vFr => {
    const vTo = vFr === "od" ? "os" : "od";
    const copyItems = [
      "_sph",
      "_cyl",
      "_axis",
      "_add",
      "_seg",
      "_oc",
      "_base",
      "_pd",
      "_npd",
      "_thk"
    ];
    copyItems.map(item =>
      this.props.setRedux(vTo + item, this.props[vFr + item])
    );
  };

  showError = (val, rangemax, rangemin) => {
    let text = "";
    let returnValue = "";
    if (val > rangemax) text = "too hight";
    else if (val < rangemin) text = "too low";
    else text = "";

    if (text !== "") {
      returnValue = (
        <Label color="red" pointing>
          {text}
        </Label>
      );
    }

    return returnValue;
  };

  render() {
    const { setAxisDisabled, axisDisabled } = this.props;
    return (
      <Table.Row>
        <Table.Cell>
          <div className="center aligned">OS</div>
        </Table.Cell>
        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_sph"
              autoComplete="off"
              value={this.props.os_sph}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_sph) !== "" &&
                  this.setState({ err_os_sph: false });
              }}              
              maxLength="6"
              className="ui small input"
              prefix={this.props.os_sph >= 0 ? "+" : ""}
              decimalScale={2}
              fixedDecimalScale={true}
              //customize field
              customInput={TextField}
              error={this.state.err_os_sph}
              variant="outlined"
            />
          </div>
          {this.showError(
            this.props.os_sph,
            this.props.powermax,
            this.props.powermin
          )}
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_cyl"
              autoComplete="off"
              value={this.props.os_cyl}
              onChange={async e => {
                let value = e.target.value.replace("+","");
                value = value.search("-") ? "+" + value : value ;
                await this.props.setRedux(e.target.name, value);
                (await this.props.os_cyl) !== "" &&
                  this.setState({ err_os_cyl: false });
                //set axis
                if (this.props.os_cyl !== "+0.00" && this.props.os_cyl !== "") {
                  //this.setState({ axis_disabled: false });
                  setAxisDisabled("os", false);
                } else {
                  //this.setState({ axis_disabled: true });
                  setAxisDisabled("os", true);
                  this.props.setRedux("os_axis", "");
                }
              }}              
              maxLength="6"
              className="ui small input"
              prefix={this.props.os_cyl >= 0 ? "+" : ""}
              decimalScale={2}
              fixedDecimalScale={true}
              //customize field
              customInput={TextField}
              error={this.state.err_os_cyl}
              variant="outlined"
            />
          </div>
          {this.showError(
            this.props.os_cyl,
            this.props.cylmax,
            this.props.cylmax * -1
          )}
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_axis"
              autoComplete="off"
              value={this.props.os_axis}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_axis) !== "" &&
                  this.setState({ err_os_axis: false });
              }}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              //customize field
              customInput={TextField}
              error={this.state.err_os_axis}
              variant={axisDisabled ? "filled" : "outlined"}
              disabled={axisDisabled}
            />
          </div>
          {this.showError(this.props.os_axis, 180, 0)}
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_add"
              autoComplete="off"
              value={this.props.os_add}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_add) !== "" &&
                  this.setState({ err_os_add: false });
              }}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              //customize field
              customInput={TextField}
              error={this.state.err_os_add}
              variant={this.state.add_disabled ? "filled" : "outlined"}
              disabled={this.state.add_disabled}
            />
          </div>
          {this.showError(this.props.os_add, this.props.addmax, 0)}
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_seg"
              autoComplete="off"
              value={this.props.os_seg}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_seg) !== "" &&
                  this.setState({ err_os_seg: false });
              }}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              //customize field
              customInput={TextField}
              error={this.state.err_os_seg}
              variant={this.state.seg_disabled ? "filled" : "outlined"}
              disabled={this.state.seg_disabled}
            />
          </div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_oc"
              value={this.props.os_oc}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_oc) !== "" &&
                  this.setState({ err_os_oc: false });
              }}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              customInput={TextField}
              variant={this.state.oc_disabled ? "filled" : "outlined"}
              disabled={this.state.oc_disabled}
              autoComplete="off"
              error={this.state.err_os_oc}
            />
          </div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_base"
              value={this.props.os_base}
              customInput={TextField}
              onChange={e => this.props.setRedux(e.target.name, e.target.value)}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              autoComplete="off"
              variant="outlined"
            />
          </div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_pd"
              value={this.props.os_pd}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (await this.props.os_pd) !== "" &&
                  this.setState({ err_os_pd: false });
                (_.toNumber(this.props.os_npd) >= _.toNumber(this.props.os_pd))
                  ? this.setState({ err_os_npd: true })
                  : this.setState({ err_os_npd: false });
              }}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              decimalScale={1}
              fixedDecimalScale={true}
              //customize field
              customInput={TextField}
              error={this.state.err_os_pd}
              variant="outlined"
              autoComplete="off"
            />
          </div>
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_npd"
              value={this.props.os_npd}
              onChange={async e => {
                await this.props.setRedux(e.target.name, e.target.value);
                (_.toNumber(this.props.os_npd) >= _.toNumber(this.props.os_pd))
                  ? this.setState({ err_os_npd: true })
                  : this.setState({ err_os_npd: false });
              }}
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              decimalScale={1}
              fixedDecimalScale={true}
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              error={this.state.err_os_npd}
            />
          </div>
          {this.showError(this.props.os_npd, this.props.os_pd, 0)}
        </Table.Cell>

        <Table.Cell>
          <div className="ui input">
            <NumberFormat
              name="os_thk"
              value={this.props.os_thk}
              onChange={e => this.props.setRedux(e.target.name, e.target.value)}              
              maxLength="6"
              className="ui small input"
              allowNegative={false}
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
            />
          </div>
        </Table.Cell>
        <Table.Cell>
        <button
            className="ui icon basic button"
            onClick={() => {
              this.copyOdToOs("os");
              axisDisabled ? setAxisDisabled("od", true) : setAxisDisabled("od", false);
            }}
          >
            <i className="arrow up icon" size="large" />
          </button>          
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.job.jobInfo };
};

export default connect(mapStateToProps, { setRedux })(RxinfoOS);
