import React from "react";
//import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="ui inverted fixed footer segment" style={{backgroundColor:"#2d3847"}}>
      <div className="ui center aligned container">
        <div className ="ui stackable inverted divided grid">
          <div className ="eight wide column">
            <img src="/bottomLogo.png" alt="logo2" />
          </div>
          <div className="eight wide column">

          <div className="ui left aligned container">
          <div className="item">
              <i className="building icon" />
              Address : 2641 W. Olympic Blvd. Los Angeles, CA 90006
            </div>          
            <div className="item">
              <i className="phone volume icon" />
              Phone : 800-123-1234
            </div>
            <div className="item">
              <i className="fax icon" />
              Fax : 800-123-1234
            </div>
            <div className="item">
              <i className="globe icon" />
              Website :
              <a href="http://www.italee.com">
                 http://www.italee.com
              </a>
            </div>            
            <div className="item">
              <i className="at icon" />
              Email :
              <a href="mailto:webmaster@italee.com">
                 webmaster@italee.com
              </a>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Footer;
