import React from "react";
//import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

class DataTable extends React.Component {
  sortable = () => {
    if (this.props.Sortable) return "sortable";
  };

  contents = val => {
    const value =
      val.colProps === "money"
        ? "$" + parseFloat(val.data).toFixed(2)
        : val.data;

    const fedexUrl = "https://www.fedex.com/apps/fedextrack/?action=track&cntry_code=us&locale=en_US&trackingnumber=";

    if (val.colProps === "tracking") return <a href={fedexUrl + value}  rel="noopener noreferrer" target="_blank"> {value} </a>;
    if (val.colProps === "invoice")
      return (
        <div className="ui button" onClick={() => this.props.showInvoice({value})}>
          {value}
        </div>
      );
    else return value;
    //return value ;
  };

  render() {
    const { tableTitle, tableBody, tableFoot, tableColor = "" } = this.props;

    const renderTableTitle = tableTitle.map(i => {
      return (
        <Table.Row key={i.key}>
          {i.title.map(j => {
            return <Table.HeaderCell key={j.key}>{j.data}</Table.HeaderCell>;
          })}
        </Table.Row>
      );
    });

    const renderTableBody = tableBody.map(i => {
      return (
        <Table.Row key={i.key}>
          {i.body.map(j => {
            return (
              <Table.Cell
                key={j.key}
                style={
                  j.colProps === "money" || j.colProps === "number"
                    ? { textAlign: "right" }
                    : { textAlign: "" }
                }
              >
                {this.contents(j)}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });

    const renderTableFoot = tableFoot.map(i => {
      return (
        <Table.Row key={i.key}>
          {i.foot.map(j => {
            return <Table.Cell key={j.key}>{j.data}</Table.Cell>;
          })}
        </Table.Row>
      );
    });

    return (
      <Table
        className={`ui compact table selectable ${tableColor} ${this.sortable}`}
      >
        <Table.Header>{renderTableTitle}</Table.Header>

        <Table.Body>{renderTableBody}</Table.Body>

        <Table.Footer>{renderTableFoot}</Table.Footer>
      </Table>
    );
  }
}

export default DataTable;
